import { DateTime } from 'luxon';

export class DateTimeHelper {
  /**
   * This method return high precision time, used principally for audio or animation purposes
   *
   * Warning: this method MUST be only used in frontend app, not send elsewhere, as time is in milliseconds
   * from page loading.
   */
  public static highPrecisionNow(): number {
    return window.performance.now();
  }

  /**
   * This method return a formatted date from a time returned by performance.now()
   */
  public static formatHighPrecisionTime(time: number): string {
    const fullTime = performance.timeOrigin + time;
    return DateTime.fromMillis(fullTime).toFormat('hh:mm:ss SSS');
  }

  public static formatDate(date: Date): string {
    return DateTime.fromJSDate(date).toISODate();
  }
}
