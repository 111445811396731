export class BotRoutes {
  // Home must have a trailing slash
  public static home = () => '/reception-point/home/';
  public static standby = () => '/reception-point/standby';
  public static newConversation = () => '/reception-point/new-conversation';
  public static userSelection = () => '/reception-point/user-selection';
  public static takePicture = () => '/reception-point/take-picture';
  public static takeIdCardPicture = () => '/reception-point/take-id-card-picture';
  public static userConfirmation = () => '/reception-point/user-confirmation';
  public static groupConfirmation = () => '/reception-point/group-confirmation';
  public static videoAssistanceConfirmation = () => '/reception-point/video-assistance-confirmation';
  public static videoAssistance = () => '/reception-point/video-assistance';
  public static visitorIdentityConfirmation = () => '/reception-point/visitor-identity-confirmation';
  public static conversationTerminated = () => '/reception-point/conversation-terminated';
  public static sentinel = () => '/reception-point/sentinel';
  public static login = () => '/reception-point/login';
  public static settings = () => '/reception-point/settings';
  public static proposeCoffee = () => '/reception-point/propose-coffee';
  public static localizationSelection = () => '/reception-point/localization-selection';
  public static localizationIndication = () => '/reception-point/localization-indication';
  public static notificationPending = () => '/reception-point/notification/pending';
  public static notificationConfirmRecipient = () => '/reception-point/notification/confirm-recipient';
  public static notificationDisplay = () => '/reception-point/notification/display';
  public static lockerCodeInput = () => '/reception-point/locker-code-input';
  public static printVisitorBadge = () => '/reception-point/visitor-badge-printing';
  public static printReceiptConfirmation = () => '/reception-point/receipt-confirmation';
  public static openLockerView = () => '/reception-point/open-locker';
  public static invitationCodeInput = () => '/reception-point/invitation-code-input';
  public static configurationAssistant = () => '/reception-point/configuration-assistant';
}
