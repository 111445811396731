import { ReceptionPointDto } from '../../reception-points';
import { BaseToken, JwtTokenType } from './BaseToken';

export interface ReceptionPointToken extends BaseToken {
  type: JwtTokenType.RECEPTION_POINT;
  receptionPoint: ReceptionPointDto;
}

export const isReceptionPointToken = (token: BaseToken): token is ReceptionPointToken => {
  return token.type === JwtTokenType.RECEPTION_POINT && (token as ReceptionPointToken).receptionPoint !== undefined;
};
