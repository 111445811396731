import { AdminRoutes, BotRoutes, CallCenterRoutes, Logger, MobileRoutes } from '@ava/frontend-shared';
import { BUILD_INFO } from './git-version';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';

const logger = Logger.get('index.tsx');
logger.warn('Version: ', BUILD_INFO);

const ava = {
  init: () => {
    const href = window.location.href;
    if (href.indexOf(MobileRoutes.passwordReset()) !== -1) {
      const newUrl = window.location.href.replace(MobileRoutes.passwordReset(), AdminRoutes.passwordReset());
      navigate(newUrl);
    }
  },
  goToReceptionPoint: () => {
    navigate(BotRoutes.home());
  },
  goToAdmin: () => {
    navigate(AdminRoutes.home());
  },
  goToCallCenter: () => {
    navigate(CallCenterRoutes.home());
  },
  goToPlayStore: () => {
    navigate('https://play.google.com/store/apps/details?id=com.brainnew.lila');
  },
  goToAppStore: () => {
    navigate('https://apps.apple.com/fr/app/lila-tech/id1579539079');
  },
};

function navigate(url: string) {
  window.location.href = url;
}

ava.init();
(window as any).ava = ava;
