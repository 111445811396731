export function solvesInAtLeast<T>(promise: Promise<T>, minDuration = 1000): Promise<T> {
  const start = Date.now();
  return new Promise<T>((resolve, reject) => {
    promise
      .then((result) => {
        const took = Date.now() - start;
        // Promise resoled after min duration, we resolve now
        if (took >= minDuration) {
          resolve(result);
        }
        // Promise resoled before min duration, we will resolve on time
        else {
          const delta = minDuration - took;
          setTimeout(() => resolve(result), delta);
        }
      })
      .catch(reject);
  });
}
