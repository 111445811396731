import { AvaErrorPayload } from './ErrorMap';

/**
 * This is an interface and not an object, because it is serialized and deserialized in HTTP requests
 */
export interface SerializableError {
  name?: string;
  message?: string;
  stack?: string;
  avaErrorPayload?: AvaErrorPayload;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function isSerializableError(err: any): err is SerializableError {
  return (err && 'message' in err && typeof err.message === 'string') || false;
}
