/**
 *  Warning: a DB migration is needed if updating existing options
 */
export enum ReceptionPointOption {
  LOCKERS = 'LOCKERS',
  RECEIPT_PRINTING = 'RECEIPT_PRINTING',
  INVITATIONS = 'INVITATIONS',
  BADGE_PRINTING = 'BADGE_PRINTING',
  SENTINEL_MODE = 'SENTINEL_MODE',
  COFFEE = 'COFFEE',
  DOC_TELLER = 'DOC_TELLER',
}

export const AllReceptionPointOptions: ReceptionPointOption[] = [
  ReceptionPointOption.LOCKERS,
  ReceptionPointOption.RECEIPT_PRINTING,
  ReceptionPointOption.INVITATIONS,
  ReceptionPointOption.BADGE_PRINTING,
  ReceptionPointOption.SENTINEL_MODE,
  ReceptionPointOption.COFFEE,
  ReceptionPointOption.DOC_TELLER,
];
