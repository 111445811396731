import { UserDto } from '../../users';
import { GroupDto } from '../../groups';

export enum ImportResultItemType {
  USER = 'USER',
  GROUP = 'GROUP',
}

export interface GroupImportDto {
  entryType?: ImportResultItemType.GROUP;
  entry?: GroupDto;
  errors: string[];
}

export interface UserImportDto {
  entryType?: ImportResultItemType.USER;
  entry?: UserDto;
  errors: string[];
}

export type ImportResultDto = GroupImportDto | UserImportDto;
