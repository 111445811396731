import { UserDto, UserFullDto } from '../users';
import { GroupDto } from '../groups';

export enum OrganizationItemType {
  USER = 'USER',
  USER_FULL = 'USER_FULL',
  GROUP = 'GROUP',
}

export interface OrganizationUser {
  type: OrganizationItemType.USER;
  user: UserDto;
}

export interface OrganizationUserFull {
  type: OrganizationItemType.USER_FULL;
  user: UserFullDto;
}

export interface OrganizationGroup {
  type: OrganizationItemType.GROUP;
  group: GroupDto;
  mainContact?: UserDto;
}

export declare type OrganizationItem = OrganizationUser | OrganizationUserFull | OrganizationGroup;
