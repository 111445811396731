import { routeReplace } from './routeReplace';

export class AdminRoutes {
  // Home must have a trailing slash
  public static readonly home = () => '/admin/home/';
  public static readonly login = () => '/admin/login';
  public static readonly passwordReset = () => '/admin/reset-password';

  public static readonly userInteractions = () => '/admin/user-interactions';
  public static readonly userInteractionsBySessionId = (sessionId?: string) => routeReplace('/admin/user-interactions/session/:sessionId', { sessionId });
  public static readonly userInteractionsBetween = (start?: string, end?: string) =>
    routeReplace('/admin/user-interactions/between/:start/:end', { start, end });
  public static readonly visitorDetections = () => '/admin/visitor-detections';
  public static readonly visitors = () => '/admin/visitors';
  public static readonly deliveries = () => '/admin/deliveries';
  public static readonly aiManagement = () => '/admin/ai-management';
  public static readonly aliasesManagement = () => '/admin/aliases-management';
  public static readonly desktopAgent = () => '/admin/desktop-agent';

  public static readonly customers = () => '/admin/customers';
  public static readonly customerCreate = () => '/admin/customers/create';
  public static readonly customerUpdate = (id?: string) => routeReplace('/admin/customers/update/:id', { id });

  public static readonly assistants = () => '/admin/assistants';
  public static readonly assistantCreate = () => '/admin/assistants/create';
  public static readonly assistantUpdate = (id?: string) => routeReplace('/admin/assistants/update/:id', { id });

  public static readonly profile = () => '/admin/profile';

  public static readonly receptionPointCreate = (customerId?: string) => routeReplace('/admin/reception-points/create/:customerId', { customerId });
  public static readonly receptionPointConfig = (id?: string) => routeReplace('/admin/reception-point/config/:id', { id });
  public static readonly receptionPointUpdate = (id?: string) => routeReplace('/admin/reception-point/update/:id', { id });
  public static readonly receptionPointFactSheet = (id?: string) => routeReplace('/admin/reception-point/fact-sheet/:id', { id });
  public static readonly receptionPointStatus = () => '/admin/reception-point/status';
  public static readonly receptionPointList = (customerId?: string) => routeReplace('/admin/reception-point/list/:customerId', { customerId });
  public static readonly receptionPointAlerts = () => '/admin/reception-points/alerts';

  public static readonly receptionPointLocalizationCreate = (id?: string) => routeReplace('/admin/reception-point/:id/localizations/create', { id });
  public static readonly receptionPointLocalizationUpdate = (id?: string, localizationId?: string) =>
    routeReplace('/admin/reception-point/:id/localizations/update/:localizationId', { id, localizationId });

  public static readonly organization = (groupId?: string) => routeReplace('/admin/organization/:groupId', { groupId });
  public static readonly organizationSearchResults = (groupId?: string, query?: string) =>
    routeReplace('/admin/organization/:groupId/search/:query', { groupId, query });
  public static readonly groupImport = (groupId?: string) => routeReplace('/admin/organization/:groupId/import', { groupId });

  public static readonly groupCreate = () => '/admin/groups/create';
  public static readonly groupUpdate = (id?: string) => routeReplace('/admin/groups/:id/update', { id });
  public static readonly groupCustomMessages = (id?: string) => routeReplace('/admin/groups/:id/custom-messages', { id });
  public static readonly groupAliases = (id?: string) => routeReplace('/admin/groups/:id/aliases', { id });
  public static readonly groupDirectoryManagement = (id?: string) => routeReplace('/admin/groups/:id/directory-management', { id });

  public static readonly userCreate = () => '/admin/users/create';
  public static readonly userUpdate = (id?: string) => routeReplace('/admin/users/:id/update', { id });
  public static readonly userAliases = (id?: string) => routeReplace('/admin/users/:id/aliases', { id });

  public static readonly lockerGroupCreate = (receptionPointId?: string) =>
    routeReplace('/admin/reception-point/:receptionPointId/locker-groups/create', { receptionPointId });
  public static readonly lockerGroupsList = (receptionPointId?: string) =>
    routeReplace('/admin/reception-point/:receptionPointId/locker-groups/list', { receptionPointId });
  public static readonly lockerGroupUpdate = (lockerGroupId?: string) => routeReplace('/admin/locker-groups/:lockerGroupId/update', { lockerGroupId });
  public static readonly lockerGroupConfig = (lockerGroupId?: string) => routeReplace('/admin/locker-groups/:lockerGroupId/configuration', { lockerGroupId });

  public static readonly apiKeysManagement = () => '/admin/api-keys';
}

export interface CustomerUpdateParams {
  id: string;
}

export interface AssistantUpdateParams {
  id: string;
}

export interface ReceptionPointConfigParams {
  id: string;
}

export interface ReceptionPointUpdateParams {
  id: string;
}

export interface ReceptionPointCreateParams {
  customerId?: string;
}

export interface ReceptionPointFactSheetParams {
  id: string;
}

export interface ReceptionPointListParams {
  customerId: string;
}

export interface LocalizationCreateParams {
  id: string;
}

export interface LocalizationUpdateParams {
  id: string;
  localizationId: string;
}

export interface LockerGroupListParams {
  receptionPointId: string;
}

export interface LockerGroupCreateParams {
  receptionPointId: string;
}

export interface LockerGroupUpdateParams {
  lockerGroupId: string;
}

export interface LockerGroupConfigParams {
  lockerGroupId: string;
}

export interface GroupUpdateParams {
  id: string;
}

export interface GroupCustomMessagesParams {
  id: string;
}

export interface GroupAliasesParams {
  id: string;
}

export interface GroupDirectoryManagementParams {
  id: string;
}

export interface UserUpdateParams {
  id: string;
}

export interface UserAliasesParams {
  id: string;
}

export interface OrganizationParams {
  groupId?: string;
  query?: string;
}

export interface UserInteractionParams {
  sessionId?: string;
  start?: string;
  end?: string;
}
