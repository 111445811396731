export interface BaseToken {
  type: JwtTokenType;
  exp: number;
  iat: number;
  websocket?: {
    /**
     * If set, this is the only Websocket  clientId authorized for this token.
     * This prevents message hijacking.
     */
    authorizedClientId?: string;

    /**
     * If set, this is the only rooms this client can join.
     * This prevents message hijacking.
     */
    authorizedStaticRooms?: string[];
  };
}

export enum JwtTokenType {
  USER = 'USER',
  USER_MOBILE = 'USER_MOBILE',
  USER_MOBILE_REFRESH = 'USER_MOBILE_REFRESH',
  SET_USER_PASSWORD = 'SET_USER_PASSWORD',

  ASSISTANT = 'ASSISTANT',
  ASSISTANT_PASSWORD_CHANGE = 'ASSISTANT_PASSWORD_CHANGE',
  ASSISTANCE = 'ASSISTANCE',

  RECEPTION_POINT = 'RECEPTION_POINT',
}
