import * as uuid from 'uuid';

export interface WsMessage<I> {
  id: string;
  body: I;
  accessToken?: string;
  // If true, message will be resent until we receive acknowledgement or until a timeout is reached
  // For the moment this feature can be used only from client side, and when backend is the recipient
  acknowledge?: boolean;
  // The id of the message to which this message replies
  replyToMessage?: string;
}

export interface WsAcknowledgment {
  type: 'WsAcknowledgment';
  messageId: string;
}

export function wrap<T>(body: T, options?: Partial<WsMessage<T>>): WsMessage<T> {
  return { id: uuid.v4(), body, ...options };
}

export function wrapReply<T>(body: T, replyToMessage: string, options?: Partial<WsMessage<T>>): WsMessage<T> {
  return { id: uuid.v4(), replyToMessage, body, ...options };
}

export function isAcknowledgement(msg: WsMessage<any>): msg is WsMessage<WsAcknowledgment> {
  return (msg.body && 'type' in msg.body && msg.body.type === 'WsAcknowledgment') || false;
}

export function acknowledgement(msg: WsMessage<unknown>, accessToken?: string): WsMessage<WsAcknowledgment> {
  return {
    id: uuid.v4(),
    body: { type: 'WsAcknowledgment', messageId: msg.id },
    accessToken,
    acknowledge: false, // Obviously !
  };
}
