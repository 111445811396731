export enum NotificationPurpose {
  VisitorWaiting = 'VisitorWaiting',
  PacketReceived = 'PacketReceived',
  GuestWaiting = 'GuestWaiting',
}

export class NotificationHelper {
  public static getTitle(purpose: NotificationPurpose): string {
    switch (purpose) {
      case NotificationPurpose.VisitorWaiting:
        return '⚠️  Visiteur en attente  ⚠️';
      case NotificationPurpose.PacketReceived:
        return '📦  Livraison  📦';
      case NotificationPurpose.GuestWaiting:
        return '⚠  Invité en attente  ⚠';
      default:
        return '📞  Notification Lila  📞';
    }
  }
}
