import axios, { AxiosError, AxiosInstance } from 'axios';
import { Logger } from '../utils';
import { GetTokenFunc } from '../authentication';

const logger = Logger.get('httpClientFactory');

export declare type OnUnauthorizedFunc = (err: AxiosError | Error | undefined) => void;

export function httpApiClient(timeout: number, getToken: GetTokenFunc, onUnauthorized?: OnUnauthorizedFunc): AxiosInstance {
  const client = axios.create({
    baseURL: '/api',
    timeout,
    responseType: 'json',
  });

  client.interceptors.request.use((config) => {
    const token = getToken();
    if (!config.headers) {
      config.headers = {};
    }

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

  client.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response?.status === 401) {
      onUnauthorized && onUnauthorized(error);
    }
    return Promise.reject(error);
  });

  return client;
}

export function httpJsonClient(timeout: number): AxiosInstance {
  return axios.create({
    timeout,
    responseType: 'json',
  });
}

/**
 * Build a Axios http client with Basic authentication info
 * @param baseURL base url
 * @param timeout timeout
 * @param username username for authentication
 * @param password password for authentication
 */
export function httpApiClientWithBasicAuth(baseURL: string, timeout: number, username: string, password: string): AxiosInstance {
  const client = axios.create({
    baseURL,
    timeout,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    auth: {
      username,
      password,
    },
  });

  return client;
}
