import { UserDto, UserFullDto } from '../users';
import { PictureBase64 } from '../common';
import { VisitorDetectionDto } from '../visitor-detections';
import { GroupDto } from '../groups';

/**
 *
 * Events are past facts sent by clients to the backend
 *
 */
export enum BotEventType {
  /**
   * Event triggered when application starts or when user reset session
   */
  NEW_CONVERSATION_STARTED = 'NEW_CONVERSATION_STARTED',

  /**
   * Event triggered when user leave reception point or terminate an action
   */
  VISITOR_LEAVES = 'VISITOR_LEAVES',

  USER_IDENTITY_CONFIRMED = 'USER_IDENTITY_CONFIRMED',

  GROUP_IDENTITY_CONFIRMED = 'GROUP_IDENTITY_CONFIRMED',

  VISITOR_IDENTITY_CONFIRMED = 'VISITOR_IDENTITY_CONFIRMED',

  PICTURE_TAKEN = 'PICTURE_TAKEN',

  USER_SELECTED = 'USER_SELECTED',

  CONVERSATION_ENDED = 'CONVERSATION_ENDED',

  LOCALIZATION_CONFIRMED = 'LOCALIZATION_CONFIRMED',

  VISITOR_DETECTION = 'VISITOR_DETECTION',

  /**
   * Event triggered when user click on the notification reply screen
   */
  VISITOR_IN_FRONT_OF_NOTIFICATION_SCREEN = 'VISITOR_IN_FRONT_OF_NOTIFICATION_SCREEN',

  /**
   * Event triggered when user confirmed that he is the right person for the notification reply
   */
  NOTIFICATION_RECIPIENT_CONFIRMED = 'NOTIFICATION_RECIPIENT_CONFIRMED',

  /**
   * Event triggered when a locker's code is received (manual input or qr scan)
   */
  LOCKER_CODE_RECEIVED = 'LOCKER_CODE_RECEIVED',

  LOCKER_PROCESSED = 'LOCKER_PROCESSED',

  RECEIPT_PROMPT_PROCESSED = 'RECEIPT_PROMPT_PROCESSED',

  VISITOR_BADGE_PRINT_PROCESSED = 'VISITOR_BADGE_PRINT_PROCESSED',

  ID_CARD_PICTURE_TAKEN = 'ID_CARD_PICTURE_TAKEN',

  INVITATION_CODE_RECEIVED = 'INVITATION_CODE_RECEIVED',

  COFFEE_PROPOSAL_REPLIED = 'COFFEE_PROPOSAL_REPLIED',
}

export interface CoffeeProposalReplied {
  type: BotEventType.COFFEE_PROPOSAL_REPLIED;
  wantCoffee: boolean;
}

export interface NewConversationStarted {
  type: BotEventType.NEW_CONVERSATION_STARTED;
}

export interface VisitorLeavesEvent {
  type: BotEventType.VISITOR_LEAVES;
}

export interface UserIdentityConfirmedEvent {
  type: BotEventType.USER_IDENTITY_CONFIRMED;
  user: UserFullDto;
}

export interface GroupIdentityConfirmedEvent {
  type: BotEventType.GROUP_IDENTITY_CONFIRMED;
  group: GroupDto;
}

export interface VisitorIdentityConfirmedEvent {
  type: BotEventType.VISITOR_IDENTITY_CONFIRMED;
  visitor: string;
}

export interface PictureTakenEvent {
  type: BotEventType.PICTURE_TAKEN;
  picture: PictureBase64;
}

export interface IdCardPictureTakenEvent {
  type: BotEventType.ID_CARD_PICTURE_TAKEN;
  picture: PictureBase64;
}

export interface UserSelectedEvent {
  type: BotEventType.USER_SELECTED;
  user: UserDto;
  /**
   * If group is specified, user was selected by clicking on this group
   */
  group?: GroupDto;
}

export interface ConversationEnded {
  type: BotEventType.CONVERSATION_ENDED;
}

export interface LocalizationConfirmedEvent {
  type: BotEventType.LOCALIZATION_CONFIRMED;
  localizationId: string;
}

export interface VisitorDetectionEvent {
  type: BotEventType.VISITOR_DETECTION;
  detection: VisitorDetectionDto;
}

export interface VisitorInFrontOfNotificationScreen {
  type: BotEventType.VISITOR_IN_FRONT_OF_NOTIFICATION_SCREEN;
  notificationId: string;
  visitor: string;
}

export interface NotificationRecipientConfirmed {
  type: BotEventType.NOTIFICATION_RECIPIENT_CONFIRMED;
  notificationId: string;
  visitor: string;
}

export interface LockerCodeReceived {
  type: BotEventType.LOCKER_CODE_RECEIVED;
  code: string;
}

export interface LockerProcessed {
  type: BotEventType.LOCKER_PROCESSED;
  error?: string;
}

export interface VisitorBadgePrintProcessed {
  type: BotEventType.VISITOR_BADGE_PRINT_PROCESSED;
  error?: string;
  printed?: boolean;
}

export interface ReceiptPromptProcessed {
  type: BotEventType.RECEIPT_PROMPT_PROCESSED;
  error?: string;
  printed?: boolean;
}

export interface InvitationCodeReceived {
  type: BotEventType.INVITATION_CODE_RECEIVED;
  secret: string;
}

export declare type BotEvent =
  | NewConversationStarted
  | VisitorLeavesEvent
  | UserIdentityConfirmedEvent
  | GroupIdentityConfirmedEvent
  | VisitorIdentityConfirmedEvent
  | PictureTakenEvent
  | ConversationEnded
  | UserSelectedEvent
  | LocalizationConfirmedEvent
  | VisitorDetectionEvent
  | VisitorInFrontOfNotificationScreen
  | NotificationRecipientConfirmed
  | LockerCodeReceived
  | LockerProcessed
  | ReceiptPromptProcessed
  | VisitorBadgePrintProcessed
  | InvitationCodeReceived
  | CoffeeProposalReplied
  | IdCardPictureTakenEvent;
