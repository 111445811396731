import { TokenHelper } from './TokenHelper';
import { GetTokenFunc } from './GetTokenFunc';

export declare type ExpiryHandler = () => void;

export interface Options {
  intervalMin: number;
  thresholdMin: number;
  getToken: GetTokenFunc;
  onExpiry: ExpiryHandler;
}

export class TokenWatcher {
  private watchTokenInterval: any;

  constructor(private options: Options) {}

  public watch() {
    this.unwatch();

    const { intervalMin, thresholdMin, getToken, onExpiry } = this.options;

    this.watchTokenInterval = setInterval(() => {
      const token = getToken();
      if (!token) {
        return;
      }

      const remaining = TokenHelper.getRemainingSecBeforeExpiration(token);
      if (remaining < thresholdMin * 60) {
        onExpiry();
      }
    }, intervalMin * 60 * 1000);
  }

  public unwatch() {
    clearInterval(this.watchTokenInterval);
  }
}
