import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { LogEvent, Logger } from '../Logger';
import { DatadogLog, logEventToDatadogLog } from './DatadogLog';
import { Environment } from '../../environment';

// https://app.datadoghq.eu/rum/application
const applicationIds: { [k: string]: string | undefined } = {
  'staging.lila.tech/frontend-admin': '698fa881-850b-41f5-84ed-c19f6d5f8278',
  'lila.tech/frontend-admin': 'ffafe0a5-116f-40bb-8538-3a5b03eb8444',
  'staging.lila.tech/frontend-callcenter': 'dbe193d0-8d4e-422e-b172-487c643ca1f9',
  'lila.tech/frontend-callcenter': 'f9cebcbb-1772-475d-9a20-fc742f40704f',
  'staging.lila.tech/frontend-receptionpoint': '11ee4ef3-2252-42f4-9471-930e01650f55',
  'lila.tech/frontend-receptionpoint': 'a6180fce-77c7-4e23-a04c-b8d6cb86cd4a',
};

// https://app.datadoghq.eu/organization-settings/client-tokens
const clientToken = 'pubf5aab4913c837197b0909ac55b96ec95';

const host = window.location.host;

const toServiceFQN = (service: string) => `${host}/${service}`;

const toServiceName = (service: string) => {
  const env = host.split('.')[0] === 'staging' ? 'staging' : 'prod';
  return `${service}-${env}`;
};

const logger = Logger.get('DatadogHelper.ts', 'info');

export class DatadogHelper {
  public static init(service: string) {
    const env = new Environment();
    if (env.isE2e()) {
      logger.info('E2E tests environment detected, Datadog will not be initialized.', host);
      return;
    }

    // Datadog should only be initialized for staging.lila.tech and lila.tech
    if (!host.includes('lila.tech')) {
      logger.info('Non production host detected, Datadog will not be initialized.', host);
      return;
    }

    const applicationId = applicationIds[toServiceFQN(service)];

    this.initDatadogRum(toServiceName(service), applicationId);
    this.initDatadogLogs(toServiceName(service));

    this.startDatadogRumRecord();

    Logger.addEventListener(DatadogHelper.logEventHandler);

    logger.info(`[${toServiceName(service)}] Datadog was initialized.`);
  }

  // https://docs.datadoghq.com/fr/real_user_monitoring/
  public static initDatadogRum(serviceName: string, applicationId?: string): void {
    if (!applicationId) {
      logger.warn('Cannot setup datadog RUM for: ', serviceName);
      return;
    }

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.eu',
      service: serviceName,
      sampleRate: 100,
      trackInteractions: true,
      trackSessionAcrossSubdomains: true,
      defaultPrivacyLevel: 'mask-user-input',
    });
  }

  public static startDatadogRumRecord(): void {
    datadogRum.startSessionReplayRecording();
  }

  public static stopDatadogRumRecord(): void {
    datadogRum.stopSessionReplayRecording();
  }

  // https://docs.datadoghq.com/fr/logs/
  public static initDatadogLogs(serviceName: string): void {
    datadogLogs.init({
      clientToken,
      site: 'datadoghq.eu',
      service: serviceName,
      trackSessionAcrossSubdomains: true,
      forwardErrorsToLogs: true,
      sampleRate: 100,
    });
  }

  /**
   * This handler is used only in web interfaces.
   * To test in local env see line 21 and 28 of this file, and comment from 36 to 39.
   * Navigate in the app to generate some logs and some event.
   * Then you can see logs in dashboard appropriate or logs search section in datadog https://app.datadoghq.eu/dashboard/lists
   * - start production build
   * Then you can see logs here: https://app.datadoghq.eu/dashboard/lists
   * And you can see session replay here: https://app.datadoghq.eu/rum/list -> Application overview
   */
  public static logEventHandler(event: CustomEvent): void {
    const ddLog: DatadogLog = logEventToDatadogLog(event.detail as LogEvent);

    switch (ddLog.logger.severity) {
      case 'debug':
        // Disabled for the moment, enable it if its necessary
        // /!\ This can generate a lot of logs
        // datadogLogs.logger.debug(ddLog.message, ddLog);
        break;
      case 'info':
        datadogLogs.logger.info(ddLog.message, ddLog);
        break;
      case 'warn':
        datadogLogs.logger.warn(ddLog.message, ddLog);
        break;
      case 'error':
        datadogLogs.logger.error(ddLog.message, ddLog);
        break;
    }
  }
}
