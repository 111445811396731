import * as QRCode from 'qrcode';
import { QRCodeToDataURLOptions } from 'qrcode';

export class QrCodeHelper {
  /**
   * Generate a qr image as DataURL (base64 prefix + image content) with input content
   * @param content
   * @param options
   */
  public static async generateDataURL(content: string, options?: QRCodeToDataURLOptions): Promise<string> {
    return QRCode.toDataURL(content, { errorCorrectionLevel: 'H', width: 500, margin: 1, ...options });
  }

  /**
   * Generate a qr image as base64 (only image content) with input content
   * @param content
   * @param options
   */
  public static async generateBase64(content: string, options?: QRCodeToDataURLOptions): Promise<string> {
    const dataUrl = await QrCodeHelper.generateDataURL(content, options);

    // The generated url is by default with the following pattern : data:image/png;base64,xxxxx
    // But we only need its base64 content (the 'xxxxx' part)
    const splitter = 'base64,';
    if (!dataUrl.includes(splitter)) {
      throw new Error('QR generation error');
    }

    return dataUrl.split('base64,')[1];
  }
}
