import {
  AssistanceProcessedResponse,
  AssistanceRequiredResponse,
  BeginAssistanceResponse,
  BeginCallResponse,
  CallOnHoldResponse,
  FrontendConfig,
  Gender,
  GroupDto,
  NotificationChannel,
  ReceptionPointDto,
  Role,
  TerminateCallResponse,
  UserDto,
  VideoCallResponseType,
  UserFullDto,
  OpenLockerResponse,
  LockerResponseType,
  LockerStatus,
  ControllerModel,
} from '@ava/backend-shared';

export class TestHelper {
  /**
   * Warning: use this is generally a very bad idea
   */
  public static wait(timeMs: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(resolve, timeMs);
    });
  }

  public static comparableObject<T extends { id: string }>(obj: T): T {
    return {
      ...obj,
      id: '#comparable-id#',
    };
  }

  public static comparableObjects<T extends { id: string }>(objs: T[]): T[] {
    return objs.map(this.comparableObject);
  }

  public static receptionPoint(): ReceptionPointDto {
    return {
      id: 'test-id',
      customerId: 'test-customerId',
      deleted: false,
      description: 'test-description',
      frontendConfig: {} as FrontendConfig,
      groupId: 'test-groupId',
      login: 'test-login',
      managedGroupId: 'test-managedGroupId',
      managedGroupPath: 'test-managedGroupPath',
      name: 'test-name',
      options: [],
      password: 'test-password',
      path: 'test-path',
      role: Role.RECEPTION_POINT,
    };
  }

  public static assistanceRequiredResponse(): AssistanceRequiredResponse {
    return {
      id: 'test-id',
      type: VideoCallResponseType.AssistanceRequired,
      assistanceId: 'test-assistance-id',
      targetId: 'test-target-id',
      label: 'test-label',
      history: {
        items: [],
      },
      receptionPoint: this.receptionPoint(),
    };
  }

  public static assistanceProcessedResponse(): AssistanceProcessedResponse {
    return {
      id: 'test-id',
      type: VideoCallResponseType.AssistanceProcessed,
      targetId: 'test-target-id',
      assistantId: 'test-assistant-id',
      assistanceId: 'test-assistance-id',
    };
  }

  public static beginAssistanceResponse(): BeginAssistanceResponse {
    return {
      id: 'test-id',
      type: VideoCallResponseType.BeginAssistance,
      targetId: 'test-target-id',
      assistantId: 'test-assistant-id',
      assistanceId: 'test-assistance-id',
      assistanceToken: '<test-assistance-token>',
    };
  }

  public static beginCallResponse(): BeginCallResponse {
    return {
      id: 'test-id',
      type: VideoCallResponseType.BeginCall,
      fromId: 'test-initiator-id',
      toId: 'test-target-id',
      token: 'test-token',
      roomName: 'test-room-name',
      callId: 'test-call-id',
      label: 'Call to the Fucking Blue Boy',
    };
  }

  public static terminateCallResponse(): TerminateCallResponse {
    return {
      id: 'test-id',
      type: VideoCallResponseType.TerminateCall,
      fromId: 'test-initiator-id',
      toId: 'test-target-id',
      callId: 'test-call-id',
    };
  }

  public static openLockerResponse(receptionPointId: string): OpenLockerResponse {
    return {
      id: 'test-id',
      type: LockerResponseType.OpenLocker,
      receptionPointId,
      locker: { id: 'locker id', label: 'locker label', status: LockerStatus.Free },
      lockerGroup: {
        id: 'locker grp id',
        controllerModel: ControllerModel.FakeController,
        deleted: false,
        groupId: 'group id',
        lockers: [],
        macAddress: 'mac',
        name: 'name',
        path: 'path',
        receptionPointId: 'rpoint id',
      },
    };
  }

  public static callOnHoldResponse(): CallOnHoldResponse {
    return {
      id: 'test-id',
      type: VideoCallResponseType.CallOnHold,
      fromId: 'test-initiator-id',
      toId: 'test-target-id',
      callId: 'test-call-id',
    };
  }

  public static user(): UserDto {
    return {
      id: 'test-id',
      firstName: 'test-firstname',
      lastName: 'test-lastname',
      phoneNumber: '0000000000',
      email: 'test@test.test',
      password: 'test-password',
      groupIds: ['test-groupId'],
      roles: [Role.ADMINISTRATOR],
      paths: [],
      deleted: false,
      gender: Gender.MAN,
      notificationMode: NotificationChannel.EMAIL,
      absences: [],
    };
  }

  public static userFull(): UserFullDto {
    return {
      id: 'test-id',
      firstName: 'test-firstname',
      lastName: 'test-lastname',
      phoneNumber: '0000000000',
      email: 'test@test.test',
      password: 'test-password',
      groupIds: ['test-groupId'],
      roles: [Role.ADMINISTRATOR],
      paths: [],
      deleted: false,
      gender: Gender.MAN,
      notificationMode: NotificationChannel.EMAIL,
      groups: [this.group()],
      absences: [],
    };
  }

  public static group(): GroupDto {
    return {
      id: 'test-id',
      name: 'test-group',
      localization: '123 Sesame Street',
      phoneNumber: '+564378',
      description: 'Description test',
      email: 'test@test.test',
      mainContact: undefined,
      secondaryContacts: [],
      parentId: '',
      path: ``,
      deleted: false,
    };
  }
}
