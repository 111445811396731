import { GroupDto } from '../groups/GroupDto';
import { Role } from '../authorization';
import { NotificationChannel } from '../notification';
import { Gender } from './Gender';
import { DirectoryProvider } from '../import-directory';
import { DateTime } from 'luxon';

export interface UserDto {
  id: string;

  firstName: string;
  lastName: string;

  phoneNumber?: string;
  email?: string;
  password?: string;

  gender: Gender;

  roles: Role[];
  title?: string;
  localization?: string;

  absences: UserAbsenceDto[];
  notificationMode?: NotificationChannel;

  groupIds: string[];
  paths: string[];
  deleted: boolean;
  metadata?: UserMetadata;
}

export interface UserMetadata {
  // If set, this user was imported from a remote a directory
  importedFrom?: {
    remoteId: string;
    source: DirectoryProvider;
  };
}

export interface UserFullDto extends UserDto {
  groups: GroupDto[];
}

export interface UserAbsenceDto {
  id: string;
  start: string;
  end: string;
  contactId: string | undefined;
  recurrence: AbsenceRecurrenceType;
}

export enum AbsenceRecurrenceType {
  NONE = 'NONE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

/**
 * Return true if user is currently absent
 * @param user
 */
export function isUserAbsent(user: UserDto): boolean {
  return !!getCurrentUserAbsence(user);
}

/**
 * If user is absent, return the active absence DTO
 * @param user
 */
export function getCurrentUserAbsence(user: UserDto): UserAbsenceDto | undefined {
  const now = DateTime.now();

  for (const absence of user.absences) {
    const nextPeriod = findNextAbsencePeriod(now, absence.recurrence, DateTime.fromISO(absence.start), DateTime.fromISO(absence.end));

    if (nextPeriod && nextPeriod.start <= now && nextPeriod.end >= now) {
      return absence;
    }
  }
}

function findNextAbsencePeriod(
  aroundDate: DateTime,
  recurrence: AbsenceRecurrenceType,
  absenceStart: DateTime,
  absenceEnd: DateTime
): { start: DateTime; end: DateTime } | undefined {
  if (recurrence === AbsenceRecurrenceType.NONE) {
    return { start: absenceStart, end: absenceEnd };
  }

  const max = 20 * 365; // max loop time for 20 years
  // luxon duration to add for each loop step
  const luxonDuration = { [recurrenceToLuxonDuration(recurrence)]: 1 };

  let i = 0;
  let currentStart = absenceStart;
  let currentEnd = absenceEnd;

  while (i < max && currentStart <= aroundDate) {
    if (currentStart <= aroundDate && currentEnd >= aroundDate) {
      return { start: currentStart, end: currentEnd };
    }

    currentStart = currentStart.plus(luxonDuration);
    currentEnd = currentEnd.plus(luxonDuration);
    i++;
  }
}

function recurrenceToLuxonDuration(recurrence: AbsenceRecurrenceType): 'day' | 'week' | 'month' | 'year' {
  switch (recurrence) {
    case AbsenceRecurrenceType.NONE:
      throw new Error('None not supported');
    case AbsenceRecurrenceType.DAILY:
      return 'day';
    case AbsenceRecurrenceType.WEEKLY:
      return 'week';
    case AbsenceRecurrenceType.MONTHLY:
      return 'month';
    case AbsenceRecurrenceType.YEARLY:
      return 'year';
  }
}
