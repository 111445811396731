export interface InteractionFilter {
  audio?: AudioFilter;
  stt?: SttFilter;
  detection?: DetectionFilter;
}

export enum AudioFilter {
  All = 'All',
  OnlyWithAudio = 'OnlyWithAudio',
}

export enum SttFilter {
  All = 'All',
  OnlyFailed = 'OnlyFailed',
}

export enum DetectionFilter {
  All = 'All',
  OnlyFailed = 'OnlyFailed',
}
