import { ReceptionPointDto } from '../reception-points';
import { ConversationHistory } from './ConversationHistory';

// TODO: rename to VideoAssistanceResponseType
export enum VideoCallResponseType {
  /**
   * This message is sent to order beginning of call
   */
  BeginCall = 'BeginCall',
  /**
   * This message is sent to order termination of current call
   */
  TerminateCall = 'TerminateCall',
  /**
   * This message is sent to order to put a call on hold
   */
  CallOnHold = 'CallOnHold',
  /**
   * This message is sent a peer tries to contact another peer which is already in call
   */
  NotAvailableForCall = 'NotAvailableForCall',

  /**
   * This message is sent when a reception point ask for video assistance
   */
  AssistanceRequired = 'AssistanceRequired',

  /**
   * This message is sent to selected peers that will process assistance.
   */
  BeginAssistance = 'BeginAssistance',

  /**
   * This message is sent when an assistance request has been processed, to all assistants
   * that will NOT handle request.
   */
  AssistanceProcessed = 'AssistanceProcessed',

  AssistanceCanceled = 'AssistanceCanceled',
}

export interface VideoCallBaseResponse {
  // Id should be unique per message
  id: string;
  type: VideoCallResponseType;
}

export interface BeginCallResponse extends VideoCallBaseResponse {
  type: VideoCallResponseType.BeginCall;
  /**
   * The ID of the peer who send message
   */
  fromId: string;
  /**
   * The id of the recipient peer
   */
  toId: string;
  /**
   * The twilio token for the call
   */
  token: string;
  /**
   * The twilio room name
   */
  roomName: string;
  /**
   * The id of the call, generated by initiator
   */
  callId: string;
  label: string;
}

export interface TerminateCallResponse extends VideoCallBaseResponse {
  type: VideoCallResponseType.TerminateCall;
  /**
   * The ID of the peer who send message
   */
  fromId: string;
  /**
   * The id of the recipient peer
   */
  toId: string;
  /**
   * The id of the call, generated by initiator
   */
  callId: string;
}

export interface CallOnHoldResponse extends VideoCallBaseResponse {
  type: VideoCallResponseType.CallOnHold;
  /**
   * The ID of the peer who send message
   */
  fromId: string;
  /**
   * The id of the recipient peer
   */
  toId: string;
  /**
   * The id of the call, generated by initiator
   */
  callId: string;
}

export interface NotAvailableForCallResponse extends VideoCallBaseResponse {
  type: VideoCallResponseType.NotAvailableForCall;
  /**
   * The ID of the peer who send message
   */
  fromId: string;
  /**
   * The id of the recipient peer
   */
  toId: string;
  /**
   * The id of the call, generated by initiator
   */
  callId: string;
}

export interface AssistanceRequiredResponse extends VideoCallBaseResponse {
  type: VideoCallResponseType.AssistanceRequired;
  assistanceId: string;
  targetId: string;
  label: string;
  history: ConversationHistory;
  receptionPoint: ReceptionPointDto;
}

export interface BeginAssistanceResponse extends VideoCallBaseResponse {
  type: VideoCallResponseType.BeginAssistance;
  /**
   * ID of the peer who accept assistance
   */
  assistantId: string;
  /**
   * ID of the peer who ask assistance
   */
  targetId: string;
  /**
   * ID of the original AssistanceRequiredResponse
   */
  assistanceId: string;
  /**
   * Token which should be used for assistance.
   */
  assistanceToken: string;
}

export interface AssistanceProcessedResponse extends VideoCallBaseResponse {
  type: VideoCallResponseType.AssistanceProcessed;
  /**
   * ID of the peer who accept assistance
   */
  assistantId: string;
  /**
   * ID of the peer who ask assistance
   */
  targetId: string;
  /**
   * ID of the assistance session
   */
  assistanceId: string;
}

export interface AssistanceCanceledResponse extends VideoCallBaseResponse {
  type: VideoCallResponseType.AssistanceCanceled;
  assistanceId: string;
}

export declare type VideoCallResponse =
  | BeginCallResponse
  | TerminateCallResponse
  | CallOnHoldResponse
  | NotAvailableForCallResponse
  | AssistanceRequiredResponse
  | BeginAssistanceResponse
  | AssistanceProcessedResponse
  | AssistanceCanceledResponse;
