import { ResponseCode } from './MobileNotificationReplyDto';
import { NotificationPurpose } from './NotificationPurpose';

export enum SenderRole {
  Bot = 'Bot',
  Assistant = 'Assistant',
}

/**
 * This entity represent a notification in user history
 */
export interface NotificationDto<T = unknown> {
  id: string;
  date: string;
  content: string;
  toUserId: string;
  senderId: string; // assistant id / reception point id
  senderRole: SenderRole;
  repliedDate?: string;
  repliedContent?: string;
  responseCode?: ResponseCode;
  purpose: NotificationPurpose;
  acknowledged: boolean; // user has read the message
  deleted: boolean;

  // this dto can hold different types of data (ex: locker code for parcel delivery) based on different NotificationPurpose
  data?: T;
}
