import { CustomMessagePurpose } from '@ava/backend-shared';

export class CustomMessagePurposeHelper {
  public static purposeText(purpose: string): string {
    switch (purpose) {
      case CustomMessagePurpose.PARCEL_DELIVERY:
        return 'Dépôt de colis';
      case CustomMessagePurpose.PLEASE_WAIT:
        return 'Veuillez patienter';
      default:
        throw new Error(`'${purpose}' not supported yet`);
    }
  }
}
