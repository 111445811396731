import * as Bowser from 'bowser';

/**
 * Current environment of frontend application. Future values could be:
 * - Election app
 * - Mobile app
 */
export enum EnvironmentType {
  /**
   * Value returned if frontend is served from localhost
   */
  Localhost = 'Localhost',
  /**
   * Value returned if frontend is in end to end tests context
   */
  E2eTests = 'E2eTests',
  /**
   * Value returned if frontend served from a non localhost domain and not for E2E tests
   */
  Production = 'Production',
}

export interface EnvironmentSource {
  window: any;
}

export type SourceProvider = () => EnvironmentSource;

const defaultProvider: SourceProvider = () => ({ window });

export class Environment {
  constructor(private source = defaultProvider) {}

  public static isBrowserValid(): boolean {
    return new Environment().isBrowserValid();
  }

  public static isE2e(): boolean {
    return new Environment().isE2e();
  }

  public isE2e(): boolean {
    return this.getType() === EnvironmentType.E2eTests;
  }

  public isProduction(): boolean {
    return this.getType() === EnvironmentType.Production;
  }

  public getType(): EnvironmentType {
    const _window = this.source().window;
    if (_window.Cypress) {
      return EnvironmentType.E2eTests;
    }
    if (_window.location.hostname === '127.0.0.1' || _window.location.hostname === 'localhost') {
      return EnvironmentType.Localhost;
    }
    return EnvironmentType.Production;
  }

  public isBrowserValid(): boolean {
    const parser = Bowser.getParser(window.navigator.userAgent);
    const isE2eEnv = this.getType() === EnvironmentType.E2eTests;
    const isBrowserValid = parser.satisfies({ chrome: '>80' }) || false;
    return isE2eEnv || isBrowserValid;
  }
}
