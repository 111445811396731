import { io, Socket } from 'socket.io-client';
import { isAcknowledgement, newWsDisconnectMessage, WebSocketNamespace, WsConnectionPayload, WsError, WsMessage } from '@ava/backend-shared';
import { Logger } from '../utils';
import { AckWaitingList } from '@ava/backend-shared';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const EventEmitter = require('event-emitter');

export const logger = Logger.get('WebSocketClient.ts', 'debug');

const defaultTimeout = 8000;

export type GetConnectionMessageFunc = () => WsMessage<WsConnectionPayload> | undefined;
export type WsMessageHandler<I> = (message: WsMessage<I>) => void;
export type WsErrorHandler = (err: WsError) => void;

export interface Parameters {
  namespace: string;
  ioFactory: typeof io;
  acknowledgement: {
    // Every XX ms the pending messages are checked and sent again if on hold for too long
    intervalMs: number;
    // Messages waiting for more than XX ms will be resent
    resendDelayMs: number;
    maxTrials: number;
  };
}

export const DefaultParameters: Parameters = {
  namespace: '',
  ioFactory: io,
  acknowledgement: {
    intervalMs: 150,
    resendDelayMs: 500,
    maxTrials: 10,
  },
};

/**
 * Websocket client.
 *
 * All clients must issue a "connection message" with a unique and stable identifier. Otherwise, at certain times, they will be inaccessible.
 */
export class WebSocketClient<Requests, Responses> {
  public static create<Req, Res>(namespace: WebSocketNamespace): WebSocketClient<Req, Res> {
    return new WebSocketClient<Req, Res>({ ...DefaultParameters, namespace });
  }

  private emitter = EventEmitter();
  private socket?: Socket;
  private waitingForAck?: AckWaitingList;
  private processed: string[] = [];
  private getConnectionMessage?: GetConnectionMessageFunc;

  constructor(private parameters: Parameters) {
    if (!parameters.namespace || !parameters.namespace.startsWith('/')) {
      throw new Error('You must provide a namespace starting with / and following url pattern.');
    }
  }

  public connect(getConnectionMessage: GetConnectionMessageFunc): void {
    if (this.socket?.connected) {
      logger.warn('Websocket client already connected');
      return;
    }

    const socket = this.parameters.ioFactory(this.parameters.namespace, {
      path: '/socket',
      multiplex: true,
      timeout: 5000,
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 2000,
    });
    this.socket = socket;

    const ackParams = this.parameters.acknowledgement;
    this.waitingForAck = AckWaitingList.create({
      intervalMs: ackParams.intervalMs,
      resendDelayMs: ackParams.resendDelayMs,
      maxTrials: ackParams.maxTrials,
      onResend: (msg) => {
        logger.warn(`📶 🔁 Resending message: ${msg.id}`);
        socket.timeout(defaultTimeout).send(msg);
      },
      onAbort: (msg) => logger.error(`📶 🛑 Message not ack: ${msg.id}`, msg),
    });
    this.waitingForAck.init();

    this.socket.on('message', this.handleBackendMessage);

    this.socket.on('disconnect', (reason) => {
      // Server may disconnect us when shutting down
      if (reason === 'io server disconnect') {
        this.socket?.connect();
      }
    });

    this.getConnectionMessage = getConnectionMessage;
    this.socket.on('connect', this.sendConnectionMessageInternal);
    this.socket.on('reconnect', this.sendConnectionMessageInternal);
  }

  public disconnect(): void {
    this.socket?.off('message', this.handleBackendMessage);
    this.socket?.off('connect', this.sendConnectionMessageInternal);
    this.socket?.off('reconnect', this.sendConnectionMessageInternal);
    this.socket?.disconnect();
    this.waitingForAck?.destroy();

    // We reset event emitters and subscribers
    this.emitter = EventEmitter();
  }

  private sendConnectionMessageInternal = () => this.sendConnectionMessage();

  /**
   * We must send connection message each time:
   * - this client connects
   * - this client reconnects
   * - the clientId changes
   *
   * We send a client id in order to join a room, and to be reachable even if
   * socket expires.
   */
  public sendConnectionMessage(volatile = false) {
    if (!this.socket || !this.getConnectionMessage) {
      logger.error('Cannot send connect message: ', { socket: this.socket, connectionMessage: this.getConnectionMessage });
      return;
    }

    const message = this.getConnectionMessage();
    if (!message) {
      logger.warn('WARNING: no connection message provided, this client can be unreachable from backend. See WebSocketClient.ts');
      return;
    }

    if (volatile) {
      this.socket.volatile.send(message);
    } else {
      this.socket.send(message);
    }
  }

  /**
   * We must send connection message each time a client want to be unreachable from backend.
   *
   * This can be different from disconnect().
   *
   * For example, at reception when disconnecting, you may want the previous assistant to be unreachable,
   * but you can keep the ws client connected.
   */
  public sendDisconnectionMessage() {
    if (!this.socket || !this.getConnectionMessage) {
      logger.error('Cannot send connect message: ', { socket: this.socket, connectionMessage: this.getConnectionMessage });
      return;
    }

    const message = this.getConnectionMessage();
    if (!message) {
      logger.warn('WARNING: no connection message provided, this client can be unreachable from backend. See WebSocketClient.ts');
      return;
    }

    const disconnectionMessage = newWsDisconnectMessage(message.body.clientId, message.accessToken as string, message.body.joinRooms);
    this.socket.send(disconnectionMessage);
  }

  private handleBackendMessage = (msg: WsMessage<unknown>) => {
    if (!this.socket || !this.waitingForAck) {
      logger.error('Not ready');
      return;
    }

    // Message is ack from backend, we remove corresponding waiting message
    if (isAcknowledgement(msg)) {
      this.waitingForAck.processAck(msg.body);
      return;
    }

    if (this.processed.includes(msg.id)) {
      logger.debug('Message discarded, already processed: ', msg);
      return;
    }
    this.processed.push(msg.id);
    this.processed = this.processed.slice(-200);

    // Message not ack, we dispatch it locally
    this.emitter.emit('message', msg);
  };

  /**
   * Send a websocket message. This method should be used for reasonable amount of messages (< 2 / s).
   *
   * If client is disconnected few seconds, message will be buffered and sent again later.
   *
   * @param msg
   * @param timeout
   */
  public send(msg: WsMessage<Requests>, timeout = defaultTimeout): void {
    if (!this.socket || !this.waitingForAck) {
      throw new Error('Not connected');
    }

    if (msg.acknowledge) {
      this.waitingForAck.push(msg);
    }

    if (!this.isConnected()) {
      logger.warn('Websocket client not connected, message may be delivered later');
    }

    // We must use timeout in order to not flood backend after reconnection
    this.socket?.timeout(timeout).send(msg);
  }

  /**
   * Send a websocket message. This method should be used for large amount of messages (> 2 / s).
   *
   * If message cannot be sent, it will be discarded.
   *
   * @param msg
   */
  public sendVolatile(msg: WsMessage<Requests>): void {
    if (!this.socket) {
      throw new Error('Not connected');
    }

    // Choose your side: send() or sendVolatile()
    if (msg.acknowledge) {
      throw new Error('You can not use ack with send sendVolatile()');
    }

    this.socket.volatile.send(msg);
  }

  public subscribe(handler: WsMessageHandler<Responses>): void {
    if (!this.socket) {
      throw new Error('Not connected');
    }

    this.emitter.on('message', handler);
  }

  public unsubscribe(handler: WsMessageHandler<Responses>): void {
    if (!this.socket) {
      throw new Error('Not connected');
    }

    this.emitter.off('message', handler);
  }

  public subscribeErrors(handler: WsErrorHandler): void {
    if (!this.socket) {
      throw new Error('Not connected');
    }

    this.socket.on('error', handler);
  }

  public unsubscribeErrors(handler: WsErrorHandler): void {
    if (!this.socket) {
      throw new Error('Not connected');
    }

    this.socket.off('error', handler);
  }

  public isConnected(): boolean {
    return this.socket?.connected || false;
  }
}
