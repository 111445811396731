import { LogEvent } from '../Logger';

export interface DatadogLog {
  // Mappings specific for Datadog see: https://app.datadoghq.eu/logs/pipelines/standard-attributes
  logger: { name: string; severity: string };
  message: string;
  data?: any;
  error?: { message: string; stack: string };
  timestamp: Date;
}

export function logEventToDatadogLog(logevent: LogEvent): DatadogLog {
  return {
    logger: {
      name: logevent.loggerName,
      severity: logevent.severity,
    },
    message: `[${logevent.loggerName}]: ${logevent.message}`,
    data: logevent.data,
    timestamp: new Date(),
  };
}
