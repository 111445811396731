export class BotUtils {
  public static ssmlToPlainText(text: string): string {
    const withoutSsml = text.replace(/<[^>]*>?/gm, '');
    return BotUtils.htmlDecode(withoutSsml);
  }

  public static htmlDecode(input: string): string {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement?.textContent || '';
  }
}
