import { Language } from '../common/Language';
import { CustomMessagePurpose } from './CustomMessagePurpose';
import { ReceptionPointOption } from '../reception-points';

export declare type MessagesByPurpose = { [k: string]: string | undefined };

export interface CustomMessagesByLang {
  lang: Language;
  messages: MessagesByPurpose;
}

const DefaultCustomMessages: CustomMessagesByLang[] = [
  {
    lang: Language.FR,
    messages: {
      [CustomMessagePurpose.PARCEL_DELIVERY]: "Merci de déposer le colis au pied de la borne. J'ai prévenu le destinataire.",
      [CustomMessagePurpose.PLEASE_WAIT]: `Votre interlocuteur a été prévenu, veuillez patienter.`,
    },
  },
  {
    lang: Language.EN,
    messages: {
      [CustomMessagePurpose.PARCEL_DELIVERY]: 'Please drop the package at the foot of the terminal. I informed the recipient.',
      [CustomMessagePurpose.PLEASE_WAIT]: 'This person has been notified, please wait.',
    },
  },
  {
    lang: Language.ES,
    messages: {
      [CustomMessagePurpose.PARCEL_DELIVERY]: 'Por favor, deje el paquete al pie del terminal. He informado al destinatario.',
      [CustomMessagePurpose.PLEASE_WAIT]: 'Esta persona ha sido notificada, por favor espere.',
    },
  },
];

const WithLockerOption: CustomMessagesByLang[] = [
  {
    lang: Language.FR,
    messages: {
      [CustomMessagePurpose.PARCEL_DELIVERY]: "Merci ! J'ai prévenu le destinataire.",
      [CustomMessagePurpose.PLEASE_WAIT]: `Votre interlocuteur a été prévenu, veuillez patienter.`,
    },
  },
  {
    lang: Language.EN,
    messages: {
      [CustomMessagePurpose.PARCEL_DELIVERY]: 'Thanks ! I informed the recipient.',
      [CustomMessagePurpose.PLEASE_WAIT]: 'This person has been notified, please wait.',
    },
  },
  {
    lang: Language.ES,
    messages: {
      [CustomMessagePurpose.PARCEL_DELIVERY]: '¡Gracias! He informado al destinatario.',
      [CustomMessagePurpose.PLEASE_WAIT]: 'Esta persona ha sido notificada, por favor espere.',
    },
  },
];

export function getDefaultCustomMessages(options: ReceptionPointOption[]): CustomMessagesByLang[] {
  const hasLocker = options.includes(ReceptionPointOption.LOCKERS);
  if (hasLocker) {
    return WithLockerOption;
  }

  return DefaultCustomMessages;
}

export function findDefaultMessage(lang: Language, purpose: string, options: ReceptionPointOption[]): string | undefined {
  return getDefaultCustomMessages(options).find((bundle) => bundle.lang === lang)?.messages[purpose];
}
