import {
  AbsenceRecurrenceType,
  Gender,
  Language,
  LockerStatus,
  NotificationChannel,
  NotificationPurpose,
  ReceptionPointOption,
  ReceptionPointStatusDto,
  Role,
  UserAbsenceDto,
} from '@ava/backend-shared';
import { DateTime } from 'luxon';

export class FormatHelper {
  public static gender(gender: Gender): string {
    switch (gender) {
      case Gender.MAN:
        return 'Homme';
      case Gender.WOMAN:
        return 'Femme';
      case Gender.UNKNOWN:
        return 'Non précisé';
    }
  }

  public static option(option: ReceptionPointOption, lang = Language.FR): string {
    switch (lang) {
      case Language.FR:
        switch (option) {
          case ReceptionPointOption.LOCKERS:
            return 'Casiers';
          case ReceptionPointOption.RECEIPT_PRINTING:
            return 'Impression de reçus de livraison';
          case ReceptionPointOption.INVITATIONS:
            return 'Invitations';
          case ReceptionPointOption.BADGE_PRINTING:
            return 'Impression de badges visiteurs';
          case ReceptionPointOption.SENTINEL_MODE:
            return 'Mode vigile';
          case ReceptionPointOption.COFFEE:
            return 'Café';
          case ReceptionPointOption.DOC_TELLER:
            return 'DocTeller';
        }
        break;
      case Language.EN:
        switch (option) {
          case ReceptionPointOption.LOCKERS:
            return 'Lockers';
          case ReceptionPointOption.RECEIPT_PRINTING:
            return 'Print delivery receipts';
          case ReceptionPointOption.INVITATIONS:
            return 'Invitations';
          case ReceptionPointOption.BADGE_PRINTING:
            return 'Print visitor badges';
          case ReceptionPointOption.SENTINEL_MODE:
            return 'Sentinel mode';
          case ReceptionPointOption.COFFEE:
            return 'Coffee';
          case ReceptionPointOption.DOC_TELLER:
            return 'DocTeller';
        }
        break;
      case Language.ES:
        switch (option) {
          case ReceptionPointOption.LOCKERS:
            return 'Casilleros';
          case ReceptionPointOption.RECEIPT_PRINTING:
            return 'Imprimir recibos de entrega';
          case ReceptionPointOption.INVITATIONS:
            return 'Invitaciones';
          case ReceptionPointOption.BADGE_PRINTING:
            return 'Imprimir insignias de visitante';
          case ReceptionPointOption.SENTINEL_MODE:
            return 'Modo centinela';
          case ReceptionPointOption.COFFEE:
            return 'Café';
          case ReceptionPointOption.DOC_TELLER:
            return 'DocTeller';
        }
    }

    return '';
  }

  public static limit(str: string, max: number): string {
    if (str.length <= max) {
      return str;
    }

    return str.substring(0, max - 3) + '...';
  }

  public static role(role: Role): string {
    switch (role) {
      case Role.SUPER_ADMIN:
        return 'Super administrateur';
      case Role.ADMINISTRATOR:
        return 'Administrateur';
      case Role.RECEPTION_POINT:
        return "Point d'accueil";
      case Role.USER:
        return 'Utilisateur';
      case Role.ASSISTANT:
        return 'Assistant';
    }
  }

  public static roleIcon(role: Role): string {
    switch (role) {
      case Role.SUPER_ADMIN:
        return 'fa fa-user-shield';
      case Role.ADMINISTRATOR:
        return 'fa fa-user-cog';
      case Role.USER:
        return 'fa fa-user';
      case Role.RECEPTION_POINT:
        return 'fa fa-robot';
      case Role.ASSISTANT:
        return 'fa fa-user-nurse';
    }
  }

  public static capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
  }

  public static notificationChannel(channel: NotificationChannel): string {
    switch (channel) {
      case NotificationChannel.SMS:
        return 'SMS';
      case NotificationChannel.EMAIL:
        return 'Mail';
      case NotificationChannel.MOBILE_APPLICATION:
        return 'Application mobile';
    }
  }

  public static getReadableStatus = (status: ReceptionPointStatusDto): string => {
    switch (status.status) {
      case 'offline':
        return 'Déconnecté';
      case 'online':
        return 'Connecté';
      default:
        return 'Inconnu';
    }
  };

  public static lockerStatus(status: LockerStatus): string {
    switch (status) {
      case LockerStatus.Free:
        return 'Libre';
      case LockerStatus.Occupied:
        return 'Occupé';
      case LockerStatus.Error:
        return 'Erreur';
      default:
        return '';
    }
  }

  public static notificationPurpose(purpose: NotificationPurpose): string {
    switch (purpose) {
      case NotificationPurpose.PacketReceived:
        return "Arrivée d'un colis";
      case NotificationPurpose.VisitorWaiting:
        return "Visiteur à l'accueil";
      case NotificationPurpose.GuestWaiting:
        return "Invité à l'accueil";
    }
  }

  public static visitorIdentity(identity: string): string {
    return this.capitalize(identity);
  }

  public static absenceRecurrence(type: AbsenceRecurrenceType): string {
    switch (type) {
      case AbsenceRecurrenceType.NONE:
        return 'Ne pas répéter';
      case AbsenceRecurrenceType.DAILY:
        return 'Tous les jours';
      case AbsenceRecurrenceType.WEEKLY:
        return 'Toutes les semaines';
      case AbsenceRecurrenceType.MONTHLY:
        return 'Tous les mois';
      case AbsenceRecurrenceType.YEARLY:
        return 'Tous les ans';
    }
  }

  public static formatAbsence(absence: UserAbsenceDto) {
    switch (absence.recurrence) {
      case AbsenceRecurrenceType.NONE:
        return FormatHelper.formatNonRecurrentAbsence(absence);
      case AbsenceRecurrenceType.DAILY:
        return FormatHelper.formatDailyAbsence(absence);
      case AbsenceRecurrenceType.WEEKLY:
        return FormatHelper.formatWeeklyAbsence(absence);
      case AbsenceRecurrenceType.MONTHLY:
        return FormatHelper.formatMonthlyAbsence(absence);
      case AbsenceRecurrenceType.YEARLY:
        return FormatHelper.formatYearlyAbsence(absence);
      default:
        throw new Error(`Unhandled type: ${absence.recurrence}`);
    }
  }

  private static formatNonRecurrentAbsence(absence: UserAbsenceDto) {
    const start = DateTime.fromISO(absence.start);
    const end = DateTime.fromISO(absence.end);
    if (start.day === end.day) {
      return `Le ${start.toFormat('dd/MM/yyyy')} de ${start.toFormat('HH:mm')} à ${end.toFormat('HH:mm')}`;
    }

    return `Du ${start.toFormat('dd/MM/yyyy à HH:mm')} au ${end.toFormat('dd/MM/yyyy à HH:mm')}`;
  }

  private static formatDailyAbsence(absence: UserAbsenceDto) {
    const startTime = DateTime.fromISO(absence.start);
    const endTime = DateTime.fromISO(absence.end);
    const diffHours = Math.round(endTime.diff(startTime, 'hours').hours);

    if (startTime.day === endTime.day) {
      return `Tous les jours de ${startTime.toFormat('HH:mm')} à ${endTime.toFormat('HH:mm')}`;
    } else {
      return `Tous les jours à partir de ${startTime.toFormat('HH:mm')} pendant ${diffHours}h`;
    }
  }

  private static formatWeeklyAbsence(absence: UserAbsenceDto) {
    const start = DateTime.fromISO(absence.start);
    const startWeekDay = start.setLocale('fr').weekdayLong;
    const startTime = start.toFormat('HH:mm');

    const end = DateTime.fromISO(absence.end);
    const endWeekDay = end.setLocale('fr').weekdayLong;
    const endTime = end.toFormat('HH:mm');

    if (startWeekDay === endWeekDay) {
      return `Tous les ${startWeekDay} de ${startTime} à ${endTime}`;
    } else {
      return `Tous les ${startWeekDay} à ${startTime} jusqu'au ${endWeekDay} à ${endTime}`;
    }
  }

  private static formatMonthlyAbsence(absence: UserAbsenceDto) {
    const start = DateTime.fromISO(absence.start);
    const startDayOfMonth = start.toFormat('dd');
    const startTime = start.toFormat('HH:mm');

    const end = DateTime.fromISO(absence.end);
    const endDayOfMonth = end.toFormat('dd');
    const endTime = end.toFormat('HH:mm');

    if (start.day === end.day) {
      return `Tous les mois le ${startDayOfMonth} de ${startTime} à ${endTime}`;
    } else {
      return `Tous les mois du ${startDayOfMonth} à ${startTime} au ${endDayOfMonth} à ${endTime}`;
    }
  }

  private static formatYearlyAbsence(absence: UserAbsenceDto) {
    const start = DateTime.fromISO(absence.start);
    const startDayOfYear = start.toFormat('dd/MM');
    const startTime = start.toFormat('HH:mm');

    const end = DateTime.fromISO(absence.end);
    const endDayOfYear = end.toFormat('dd/MM');
    const endTime = end.toFormat('HH:mm');

    if (startDayOfYear === endDayOfYear) {
      return `Tous les ans le ${startDayOfYear} de ${startTime} à ${endTime}`;
    } else {
      return `Tous les ans du ${startDayOfYear} à ${startTime} au ${endDayOfYear} à ${endTime}`;
    }
  }

  public static phoneNumber(number: string): string {
    let result = '';
    result += number.slice(0, 3);
    for (let i = 3; i < number.length; i += 2) {
      result += ' ' + number.slice(i, i + 2);
    }

    return result;
  }
}
