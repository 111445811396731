export enum InteractionStatusValue {
  Ok = 'Ok',
  Misunderstanding = 'Misunderstanding',
  Error = 'Error',
  Unknown = 'Unknown',
  Canceled = 'Canceled',
}

export interface InteractionStatus {
  value: InteractionStatusValue;
  label: string;
}

export class InteractionStatuses {
  public static readonly Ok: InteractionStatus = {
    value: InteractionStatusValue.Ok,
    label: 'Ok',
  };

  public static readonly Misunderstanding: InteractionStatus = {
    value: InteractionStatusValue.Misunderstanding,
    label: 'Incompréhension',
  };

  public static readonly Error: InteractionStatus = {
    value: InteractionStatusValue.Error,
    label: 'Erreur',
  };

  public static readonly Canceled: InteractionStatus = {
    value: InteractionStatusValue.Canceled,
    label: 'Non significative',
  };

  public static readonly Unknown: InteractionStatus = {
    value: InteractionStatusValue.Unknown,
    label: 'Inconnu',
  };

  public static readonly ALL: InteractionStatus[] = [
    InteractionStatuses.Ok,
    InteractionStatuses.Misunderstanding,
    InteractionStatuses.Error,
    InteractionStatuses.Unknown,
    InteractionStatuses.Canceled,
  ];

  public static forValue(value: InteractionStatusValue): InteractionStatus {
    return this.ALL.find((st) => st.value === value) || this.Unknown;
  }
}
