import { Gender } from './Gender';
import { Language } from '../common';
import { UserDto } from './UserDto';

// For the moment civility titles are used only to determine gender
// But they should be used soon for doctors, professors, ...
export enum CivilityTitle {
  Mister = 'Mister',
  Madam = 'Madam',
  Unknown = '',
}

export const CivilityDict = {
  [Language.FR]: {
    [Gender.MAN]: 'Monsieur',
    [Gender.WOMAN]: 'Madame',
    [Gender.UNKNOWN]: '',
  },
  [Language.EN]: {
    [Gender.MAN]: 'Mister',
    [Gender.WOMAN]: 'Madam',
    [Gender.UNKNOWN]: '',
  },
  [Language.ES]: {
    [Gender.MAN]: 'Señor',
    [Gender.WOMAN]: 'Señora',
    [Gender.UNKNOWN]: '',
  },
};

export const ShortCivilityDict = {
  [Language.FR]: {
    [Gender.MAN]: 'M',
    [Gender.WOMAN]: 'Mme',
    [Gender.UNKNOWN]: '',
  },
  [Language.EN]: {
    [Gender.MAN]: 'Mr',
    [Gender.WOMAN]: 'Mrs',
    [Gender.UNKNOWN]: '',
  },
  [Language.ES]: {
    [Gender.MAN]: 'Sr',
    [Gender.WOMAN]: 'Sra',
    [Gender.UNKNOWN]: '',
  },
};

export class CivilityHelper {
  public static toGender(civility: CivilityTitle | string | undefined): Gender | undefined {
    switch (civility) {
      case CivilityTitle.Mister:
        return Gender.MAN;
      case CivilityTitle.Madam:
        return Gender.WOMAN;
      case CivilityTitle.Unknown:
        return Gender.UNKNOWN;
    }
  }

  public static fromGender(gender: Gender, lang: Language): string {
    return CivilityDict[lang][gender];
  }

  public static formatUser(user: UserDto, lang: Language): string {
    if (!user.gender) {
      throw new Error('Gender is mandatory');
    }

    const title = CivilityDict[lang][user.gender];
    if (title) {
      return `${title} ${user.lastName}`;
    } else {
      return `${user.firstName} ${user.lastName}`;
    }
  }

  public static formatUserMedium(user: UserDto, lang: Language): string {
    if (!user.gender) {
      throw new Error('Gender is mandatory');
    }

    //Should not have a '.' if the gender is unknown
    const smallTitle = ShortCivilityDict[lang][user.gender] ? ShortCivilityDict[lang][user.gender] + '.' : ShortCivilityDict[lang][user.gender];

    return `${smallTitle} ${user.firstName} ${user.lastName}`.trim();
  }

  public static formatUserLong(user: UserDto, lang: Language): string {
    if (!user.gender) {
      throw new Error('Gender is mandatory');
    }

    const title = CivilityDict[lang][user.gender];
    return `${title} ${user.firstName} ${user.lastName}`.trim();
  }
}
