import { ReceptionPointDto } from '../reception-points';
import { ConversationHistory } from './ConversationHistory';

// TODO: rename to VideoAssistanceRequestType
export enum VideoCallRequestType {
  /**
   * This message is sent when a peer wants to call another peer
   */
  Call = 'Call',
  /**
   * This message is sent when a peer wants to terminate a call with another peer
   */
  TerminateCall = 'TerminateCall',
  /**
   * This message is sent when a peer wants to put a call on hold
   */
  CallOnHold = 'CallOnHold',
  /**
   * This message is sent if a peer tries to connect another peer who is already in call
   */
  NotAvailableForCall = 'NotAvailableForCall',

  AskAssistance = 'AskAssistance',

  AcceptAssistance = 'AcceptAssistance',

  CancelAssistance = 'CancelAssistance',
}

export enum PeerType {
  Assistant = 'Assistant',
  ReceptionPoint = 'ReceptionPoint',
  MobileApplication = 'MobileApplication',
}

export interface VideoCallBaseRequest {
  // Id should be unique per message
  id: string;
  type: VideoCallRequestType;
  fromId: string;
}

export interface CallRequest extends VideoCallBaseRequest {
  type: VideoCallRequestType.Call;
  /**
   * The id of the recipient peer
   */
  toId: string;
  /**
   * The id of the call, generated by initiator
   */
  callId: string;

  label: string;
}

export interface TerminateCallRequest extends VideoCallBaseRequest {
  type: VideoCallRequestType.TerminateCall;
  /**
   * The id of the recipient peer
   */
  toId: string;
  /**
   * The id of the call, generated by initiator
   */
  callId: string;
}

export interface CallOnHoldRequest extends VideoCallBaseRequest {
  type: VideoCallRequestType.CallOnHold;
  /**
   * The id of the recipient peer
   */
  toId: string;
  /**
   * The id of the call, generated by initiator
   */
  callId: string;
}

export interface NotAvailableForCallRequest extends VideoCallBaseRequest {
  type: VideoCallRequestType.NotAvailableForCall;
  /**
   * The id of the recipient peer
   */
  toId: string;
  /**
   * ID of the current call
   */
  callId: string;
}

export interface AskAssistanceRequest extends VideoCallBaseRequest {
  type: VideoCallRequestType.AskAssistance;
  /**
   * ID of the assistance session
   */
  assistanceId: string;
  label: string;
  history: ConversationHistory;
  receptionPoint: ReceptionPointDto;
}

export interface AcceptAssistanceRequest extends VideoCallBaseRequest {
  type: VideoCallRequestType.AcceptAssistance;
  /**
   * ID of the peer who ask assistance
   */
  targetId: string;
  /**
   * ID of the assistance session
   */
  assistanceId: string;
}

export interface CancelAssistanceRequest extends VideoCallBaseRequest {
  type: VideoCallRequestType.CancelAssistance;
  assistanceId: string;
}

export declare type VideoCallRequest =
  | CallRequest
  | TerminateCallRequest
  | CallOnHoldRequest
  | NotAvailableForCallRequest
  | AskAssistanceRequest
  | AcceptAssistanceRequest
  | CancelAssistanceRequest;
