import { wrap, WsMessage } from './WsMessage';

const WsConnectionPayloadType = 'WsConnectionPayload';

/**
 * This message must be sent each time a client connect, or reconnect
 */
export interface WsConnectionPayload {
  type: 'WsConnectionPayload';
  clientId: string;
  // All rooms names here will be joined too
  joinRooms: string[];
}

export function newWsConnectMessage(clientId: string, accessToken: string, joinRooms: string[] = []): WsMessage<WsConnectionPayload> {
  return wrap(
    {
      type: WsConnectionPayloadType,
      clientId,
      joinRooms,
    },
    { accessToken }
  );
}

export function isWsConnectionMessage(message: WsMessage<any> | undefined): message is WsMessage<WsConnectionPayload> {
  return (message?.body && 'type' in message.body && message.body.type === WsConnectionPayloadType) || false;
}

export function isWsConnectionPayload(payload: any | undefined): payload is WsConnectionPayload {
  return (payload && 'type' in payload && payload.type === WsConnectionPayloadType) || false;
}
