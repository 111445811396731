import { BotEvent, BotEventType } from './BotEvent';
import { Language } from '../common/Language';
import { ReceptionPointDto } from '../reception-points/ReceptionPointDto';

export interface BotRequest {
  /**
   * Uniq ID of request
   */
  id: string;
  /**
   * Id of a conversation
   */
  sessionId: string;

  /**
   * Text input from user
   */
  text?: string;

  /**
   * Base64 encoded incoming user request audio, sent to NLP
   */
  audio?: string;

  /**
   * An event triggered by frontend, by user, home automation, etc ...
   */
  event?: BotEvent;

  /**
   * Language code
   */
  language: Language;

  /**
   * Contains information about Ava context: work group, location , etc ...
   */
  context: BotContext;
}

export interface BotContext {
  /**
   * This field is overwritten in backend with values from authentication token
   */
  receptionPoint: ReceptionPointDto;
}

/**
 * This function erase large field of request
 */
export function lightBotRequest(req: BotRequest): BotRequest {
  const result: BotRequest = {
    ...req,
    audio: req.audio ? '<audio-was-erased>' : undefined,
  };

  if (req.event?.type === BotEventType.PICTURE_TAKEN) {
    result.event = {
      ...req.event,
      picture: { content: '<picture-data>' },
    };
  }

  return result;
}
