import { WsAcknowledgment, WsMessage } from './WsMessage';

export type MessageHandler = (msg: WsMessage<any>) => void;

export interface Parameters {
  intervalMs: number;
  resendDelayMs: number;
  maxTrials: number;
  onResend: MessageHandler;
  onAbort: MessageHandler;
}

interface WaitingForAck {
  msg: WsMessage<unknown>;
  lastSend: number;
  remaining: number;
}

/**
 * This class manages the websocket message acknowledgment tracking.
 *
 * Once init, you push() messages that need ack, and processAck() when ack received.
 *
 * Every XX ms, this class will emit messages that did not received ack and need to be resent.
 *
 * This system is designed to ensure that messages are not lost event if there are process interruptions,
 * but for a short period only (~20s), in order to not flood backend.
 */
export class AckWaitingList {
  public static create(params: Parameters): AckWaitingList {
    return new AckWaitingList({ ...params });
  }

  private resendInterval: any;
  private waitingForAck: WaitingForAck[] = [];

  constructor(private parameters: Parameters) {}

  public init(): void {
    this.resendInterval = setInterval(this.resendWaitingForAck, this.parameters.intervalMs);
  }

  public destroy(): void {
    clearInterval(this.resendInterval);
  }

  public push(msg: WsMessage<any>): void {
    this.waitingForAck.push({ msg, lastSend: Date.now(), remaining: this.parameters.maxTrials });
  }

  public processAck(ack: WsAcknowledgment): void {
    this.waitingForAck = this.waitingForAck.filter((waiting) => waiting.msg.id !== ack.messageId);
  }

  private resendWaitingForAck = () => {
    const now = Date.now();
    const { onResend, onAbort, resendDelayMs, maxTrials } = this.parameters;

    this.waitingForAck = this.waitingForAck
      .map((waiting) => {
        const trial = maxTrials - waiting.remaining;
        const waitTime = Math.max(resendDelayMs * trial * 0.5, resendDelayMs);
        const timeToResend = now - waiting.lastSend >= waitTime;
        const sendTooManyTimes = waiting.remaining < 1;

        // No ack received, we resent message
        if (timeToResend && !sendTooManyTimes) {
          onResend(waiting.msg);
          return { ...waiting, remaining: waiting.remaining - 1, lastSend: Date.now() };
        }
        // Message will not be delivered, it has been resent too many times
        else if (timeToResend && sendTooManyTimes) {
          onAbort(waiting.msg);
          return null;
        }
        // Not time to resend this message
        else {
          return waiting;
        }
      })
      .filter((waiting) => !!waiting) as WaitingForAck[];
  };
}
