/**
 * WARNING: modifying this will require a data migration
 */
export enum Role {
  /**
   * Administrators are users with very high privileges. They can edit and delete all.
   *
   * /!\ Only Brain new employees must be super admin, never customers.
   */
  SUPER_ADMIN = 'SUPER_ADMIN',

  /**
   * Administrators are users with high privileges. They can edit and delete all their resources.
   *
   */
  ADMINISTRATOR = 'ADMINISTRATOR',

  /**
   * Reception points can only read resources
   *
   */
  RECEPTION_POINT = 'RECEPTION_POINT',

  /**
   * Simple user, can do anything for the moment
   */
  USER = 'USER',

  /**
   * Assistant, can read resources, and modify some informations (such as fact sheet, etc.)
   */
  ASSISTANT = 'ASSISTANT',
}
