// FIXME: REFACTOR: When authentication will be set in callcenter, we should delete these entities then serve
// FIXME: REFACTOR: an assistance dto from backend containing history and other informations

export interface ConversationHistory {
  items: HistoryItem[];
}

export enum HistoryItemType {
  Request = 'Request',
  Response = 'Response',
}

export interface Request {
  type: HistoryItemType.Request;
  text: string;
  when: number;
}

export interface Response {
  type: HistoryItemType.Response;
  text: string;
  when: number;
}

export type HistoryItem = Request | Response;
