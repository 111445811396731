import { ActionSuggestion } from './ActionSuggestion';
import { BotCommand } from './BotCommand';
import { SerializableError } from '../errors';
import { I18nText } from '../common';
import { UserDto } from '../users';
import { GroupDto } from '../groups';

export interface BotResponse {
  /**
   * Uniq ID of message
   */
  id: string;

  /**
   * Uniq ID of corresponding request
   */
  requestId?: string;

  /**
   * Id of a conversation
   */
  sessionId: string;

  /**
   * Disable speech for text response
   */
  mute?: boolean;

  /**
   * Text response from Ava
   */
  text?: I18nText;

  /**
   * Audio response from Ava, base 64 encoded
   */
  audio?: string;

  /**
   * Suggestions will be shown to user on window bottom
   */
  suggestions?: ActionSuggestion[];

  /**
   * If received, must trigger specified command
   */
  command?: BotCommand;

  metadata?: BotResponseMetadata;
}

export interface BotResponseMetadata {
  /**
   * Text understood by bot in last request
   */
  queryText?: string;

  /**
   * If set, an error has occurred
   */
  error?: SerializableError;

  /**
   * Unique identifier of response text
   */
  responseTextId?: string;

  /**
   * If set, associated request was not understood, either because NLP analysis failed or because requests
   * has not corresponding skill
   */
  notUnderstood?: boolean;

  /**
   * If true, this is the first message displayed by Lila
   */
  welcomeMessage?: boolean;

  /**
   * If set, the current user / group is selected for further actions.
   *
   * This variable is mainly used for UI.
   */
  selection?: {
    user?: UserDto;
    group?: GroupDto;
  };
}

/**
 * This function erase large field of response
 */
export function lightBotResponse(req: BotResponse): BotResponse {
  return {
    ...req,
    audio: req.audio ? '<audio-was-erased>' : undefined,
  };
}
