import { AvaErrorCode, AvaErrorPayload } from './ErrorMap';

export const getAvaErrorMessage = (avaErrorPayload: AvaErrorPayload): string => {
  switch (avaErrorPayload.code) {
    case AvaErrorCode.USER_NOT_FOUND_WITH_ID:
      return `L'utilisateur n'existe pas`;

    case AvaErrorCode.USER_NOT_FOUND_WITH_EMAIL:
      return `Cet email n'est pas enregistré : ${avaErrorPayload.email}`;

    case AvaErrorCode.USER_GROUP_MANDATORY:
      return `L'utilisateur doit avoir au moins un groupe`;

    case AvaErrorCode.USER_MAIL_NOT_UNIQUE:
      return `Cet email est déjà utilisé par un autre utilisateur`;

    case AvaErrorCode.USER_MAIL_EMPTY:
      return `L'email de l'utilisateur n'est pas enregistré`;

    case AvaErrorCode.CUSTOMER_ADMIN_MAIL_MANDATORY:
      return `L'email de l'administrateur est obligatoire`;

    case AvaErrorCode.CUSTOMER_NOT_FOUND_WITH_ID:
      return `Le client n'existe pas`;

    case AvaErrorCode.CUSTOMER_DOMAIN_NOT_VALID:
      return `Le domaine du client n'est pas valide`;

    case AvaErrorCode.GROUP_PARENT_ID_MANDATORY:
      return `L'identifiant du groupe parent est obligatoire`;

    case AvaErrorCode.GROUP_NOT_FOUND_WITH_ID:
      return `Le groupe n'existe pas`;

    case AvaErrorCode.GROUP_DELETE_ERROR_WITH_SUB_GROUPS:
      return `Impossible de supprimer un groupe comportant des sous-groupes`;

    case AvaErrorCode.GROUP_DELETE_ERROR_WITH_USERS:
      return `Impossible de supprimer un groupe comportant des utilisateurs`;

    case AvaErrorCode.GROUP_DELETE_ERROR_WITH_RPOINTS:
      return `Impossible de supprimer un groupe comportant des points d'accueil`;

    case AvaErrorCode.GROUP_NAME_NOT_UNIQUE:
      return `Le nom du groupe est déjà pris`;

    case AvaErrorCode.GROUP_DELETE_ERROR_WITH_CUSTOMER:
      return `Vous ne pouvez pas supprimer le groupe principal d'un client`;

    case AvaErrorCode.ASSISTANT_LOGIN_NOT_UNIQUE:
      return `Le login est déjà pris`;

    case AvaErrorCode.ASSISTANT_EMAIL_NOT_UNIQUE:
      return `L'email est déjà pris`;

    case AvaErrorCode.NOTIFICATION_NO_EMAIL_FOUND:
      return `Nous n'avons pas trouvé d'email, vous pouvez essayer avec un autre canal`;

    case AvaErrorCode.NOTIFICATION_NO_PHONE_NUMBER_FOUND:
      return `Nous n'avons pas trouvé de numéro de téléphone, vous pouvez essayer avec un autre canal`;

    case AvaErrorCode.NOTIFICATION_PHONE_NUMBER_INVALID:
      return `Le numéro de téléphone n'est pas valide, vous pouvez essayer avec un autre canal`;

    case AvaErrorCode.LOCKER_IZYBAT_LOCKERID_UNIQUE:
      return `L'id du casier IzyBat existe déjà`;

    case AvaErrorCode.INVALID_PASSWORD:
      return `Le mot de passe est incorrect.`;

    case AvaErrorCode.DEFAULT_ERROR:
    default:
      return 'Une erreur est survenue, veuillez réessayer plus tard';
  }
};
