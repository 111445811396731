import { LockerDto, LockerGroupDto } from '../locker-group';

export enum LockerResponseType {
  OpenLocker = 'OpenLocker',
  GetLockersStatus = 'GetLockersStatus',
}

export interface LockerBaseResponse {
  // Id should be unique per message
  id: string;
  // Target reception point which should handle the message
  receptionPointId: string;
  type: LockerResponseType;
}

export interface OpenLockerResponse extends LockerBaseResponse {
  type: LockerResponseType.OpenLocker;
  lockerGroup: LockerGroupDto;
  locker: LockerDto;
}

export interface GetLockersStatusResponse extends LockerBaseResponse {
  type: LockerResponseType.GetLockersStatus;
  lockerGroup: LockerGroupDto;
}

export declare type WsLockerResponse = OpenLockerResponse | GetLockersStatusResponse;
