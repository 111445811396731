import { LockerGroupDto } from '../locker-group/LockerGroupDto';
import { LockerDoorStatus } from '../locker-group/LockerDoorStatus';

export enum LockerRequestType {
  OpenLockerStatus = 'OpenLockerStatus',
  GetLockersStatus = 'GetLockersStatus',
}

/**
 * This message is sent from reception point to backend after an "open" operation.
 *
 * It contains the state of the target locker door.
 */
export interface OpenLockerStatus {
  id: string;
  type: LockerRequestType.OpenLockerStatus;
  status: LockerDoorStatus;
}

/**
 * This message is sent from reception point to backend after an "get status" operation.
 *
 * It contains the latest state of the all lockers door.
 */
export interface GetLockersStatus {
  id: string;
  type: LockerRequestType.GetLockersStatus;
  // lockerGroup will be empty if GetLockersStatus got some errors
  lockerGroup?: LockerGroupDto;
}

export declare type WsLockerRequest = OpenLockerStatus | GetLockersStatus;
