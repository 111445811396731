/**
 * This interface is used to type window, in reception point.
 *
 * Fields added here are mainly used for development and testing purposes.
 *
 */
export interface ReceptionPointWindow extends Window {
  /**
   * This is our namespace, tools are set here for debug purposes only, no type needed
   *
   */
  ava: {
    services: any;
    bot: any;

    /**
     * This is a development shortcut, to send text messages to Ava. It is used in E2E tests too.
     * @param message
     */
    send: (message: string) => void;
    store: any;
  };

  /**
   * This field is used by Redux development plugin
   */
  __REDUX_DEVTOOLS_EXTENSION__?: any;
}

export function getReceptionPointWindow(): ReceptionPointWindow {
  const _window = window as any;
  if (!_window.ava) {
    _window.ava = {};
  }
  return _window;
}
