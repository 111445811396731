/* eslint-disable no-console */

// WARNING: this is a debug tool, this should not be used in production

// We do not start at zero in order to not trigger function the first time
let i = 1;

// Use this with caution, and for development purposes only
export function intermittentRun(func: () => void, otherwise?: () => void, onceEveryXTimes = 3): void {
  if (i % onceEveryXTimes === 0) {
    console.error('[🐞][intermittentRun] Executed');
    func();
  } else {
    console.error('[🐞][intermittentRun] Not executed this time !');
    otherwise && otherwise();
  }
  i++;
}

export function intermittentDeath(onceEveryXTimes = 6): void {
  intermittentRun(() => process.exit(1), undefined, onceEveryXTimes);
}
