import { MobileNotificationReplyDto } from './MobileNotificationReplyDto';

export enum NotificationResponseType {
  REPLY_RECEIVED = 'REPLY_RECEIVED',
}

export interface NotificationReplyReceived {
  type: NotificationResponseType.REPLY_RECEIVED;
  fromId: string;
  message: MobileNotificationReplyDto;
}

export declare type NotificationWsResponse = NotificationReplyReceived;
