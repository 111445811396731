import { connect, ConnectOptions, RemoteParticipant, RemoteTrackPublication, Room, TwilioError } from 'twilio-video';
import { Logger } from '../utils';
import { Environment } from '../environment';

export const logger = Logger.get('TwilioStream', 'info');

export enum TwilioStreamEvents {
  STOPPED = 'STOPPED',
  ERROR = 'ERROR',
}

export type EventHandler = (ev: CustomEvent) => void;

/**
 * Wrapper around Twilio API. See: https://media.twiliocdn.com/sdk/js/video/releases/2.13.1/docs/
 *
 *  Here we add event dispatching for errors in order to clean state of frontends. But that can be
 *  a bad idea, twilio sdk can reconnect itself after error.
 */
export class TwilioStream {
  public static create(roomName: string, token: string, videoElement?: HTMLVideoElement): TwilioStream {
    const stream = new TwilioStream(roomName, token, connect);
    stream.setVideoElement(videoElement);
    return stream;
  }

  private room?: Room;
  private videoElement?: HTMLVideoElement;
  private connected = false;
  private eventTarget = document.createDocumentFragment();
  private audioSettings: MediaTrackConstraints | true = true;
  private videoSettings: MediaTrackConstraints | true = true;

  constructor(public readonly roomName: string, public readonly token: string, private connectToRoom: typeof connect) {}

  public async start(): Promise<void> {
    if (Environment.isE2e()) {
      logger.warn('Video stream will not start in E2E environment');
      return;
    }

    if (!this.videoElement) {
      throw new Error('Video element is mandatory before start');
    }

    const options: ConnectOptions = {
      name: this.roomName,
      audio: this.audioSettings,
      video: this.videoSettings,
      region: 'de1',
    };

    logger.info('Starting stream with parameters: ', options);
    this.room = await this.connectToRoom(this.token, options);
    this.connected = true;

    this.setupDisconnectionListeners();
    this.setupConnectionListeners();
  }

  public setMediaSettings(audioSettings: MediaTrackConstraints | true, videoSettings: MediaTrackConstraints | true) {
    this.audioSettings = audioSettings;
    this.videoSettings = videoSettings;
  }

  public stop() {
    this.room?.disconnect();

    // Detach remote tracks
    this.room?.participants.forEach((participant) => {
      participant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track && track.kind !== 'data') {
          track.detach();
        }
      });
    });

    // Detach and stop local tracks
    this.room?.localParticipant.tracks.forEach((publication) => {
      const track = publication.track;
      if (track && track.kind !== 'data') {
        track.detach();
        track.stop();
      }
    });

    // Clean video element
    if (this.videoElement) {
      this.videoElement.pause();
      this.videoElement.src = '';
    }

    this.connected = false;
  }

  private setupConnectionListeners() {
    const room = this.room;
    if (!room) {
      throw new Error('Room not set');
    }

    const handleParticipant = (participant: RemoteParticipant) => {
      participant.tracks.forEach((publication: RemoteTrackPublication) => {
        logger.debug('Participant have already published track: ', { publication, isSubscribed: publication.isSubscribed });

        // Here we MUST handle both cases: when publication is subscribed and not. If we not, video calls will work 2/3 times.
        if (publication.isSubscribed) {
          this.attachTrack(publication);
        } else {
          publication.on('subscribed', () => this.attachTrack(publication));
        }

        publication.on('subscriptionFailed', (err) => this.handleError(err));
      });

      participant.on('trackSubscribed', (publication: RemoteTrackPublication) => {
        logger.debug('Participant published track: ', { publication });
        this.attachTrack(publication);
      });

      participant.on('trackSubscriptionFailed', (err) => this.handleError(err));
    };

    // We listen for new participants
    room.on('participantConnected', (participant) => {
      logger.debug(`Participant "${participant.identity}" connected`);
      handleParticipant(participant);
    });

    // We handle already connected participants
    room.participants.forEach((participant) => {
      logger.debug(`Participant "${participant.identity}" was already connected`);
      handleParticipant(participant);
    });
  }

  private setupDisconnectionListeners() {
    const room = this.room;
    if (!room) {
      throw new Error('Room not set');
    }

    // Notify listeners we are left alone in room
    room.on('participantDisconnected', (ev) => {
      const participants = room.participants;
      logger.debug('Participant disconnected : ', { ev, participants });

      if (!participants.size) {
        this.eventTarget.dispatchEvent(new CustomEvent(TwilioStreamEvents.STOPPED));
        return;
      }

      const localSid = room.localParticipant.sid;
      if (participants.size === 1 && participants.get(localSid)) {
        this.eventTarget.dispatchEvent(new CustomEvent(TwilioStreamEvents.STOPPED));
      }
    });

    room.on('disconnected', (_room, error) => {
      logger.debug(`Room disconnected: ${room.name}`);

      if (error) {
        this.handleError(error);
      }

      room.localParticipant.tracks.forEach((publication) => {
        const track = publication.track;
        if (track && track.kind !== 'data') {
          track.detach();
        }
      });
    });
  }

  private attachTrack(publication: RemoteTrackPublication): void {
    const track = publication.track;

    if (!track) {
      logger.debug('No track present in publication', publication);
      return;
    }

    if (!this.videoElement) {
      logger.error('Video element not set or invalid track, track will not be attached', { videoElement: this.videoElement, track });
      return;
    }

    // We only attach video and audio tracks to element
    if (track.kind !== 'data') {
      logger.debug('Track attached: ', publication.track);
      track.attach(this.videoElement);
    } else {
      logger.warn('Track will not be attached: ', { track });
    }
  }

  public addEventListener(type: TwilioStreamEvents, handler: EventHandler) {
    this.eventTarget.addEventListener(type, handler as EventListener);
  }

  public removeEventListener(type: TwilioStreamEvents, handler: EventHandler) {
    this.eventTarget.removeEventListener(type, handler as EventListener);
  }

  /**
   * This method does not return true state of connection. It returns true if a connection occurred,
   * and false if no connection occurred or if stop() has been called.
   *
   * We need to keep this behavior because disconnection take time and we need to switch rapidly
   * between calls in call center.
   */
  public isConnected(): boolean {
    return this.connected;
  }

  /**
   * Video element where tracks will be attached.
   *
   * You MUST set video element before call start.
   *
   * @param video
   */
  public setVideoElement(video: HTMLVideoElement | undefined) {
    this.videoElement = video;
  }

  public getVideoElement(): HTMLVideoElement | undefined {
    return this.videoElement;
  }

  private handleError(error: TwilioError): void {
    logger.error('Twilio video stream error: ', error);
    this.eventTarget.dispatchEvent(new CustomEvent(TwilioStreamEvents.ERROR));
  }
}
