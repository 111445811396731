export enum AvatarName {
  ASH = 'ASH',
  UNICORN = 'UNICORN',
  LILA = 'LILA',
  JOE = 'JOE',
  SENTINEL = 'SENTINEL',
}

export const AllAvatarNames = [AvatarName.ASH, AvatarName.UNICORN, AvatarName.LILA, AvatarName.JOE, AvatarName.SENTINEL];

/**
 * Get the name of the avatar to use for the response / prononce
 */
export function getAvatarNameForResponse(avatarName?: AvatarName): string {
  switch (avatarName) {
    case AvatarName.ASH:
    case AvatarName.LILA:
      return 'Lila';

    case AvatarName.JOE:
      return 'Tom';
    default:
      return 'Lila';
  }
}
