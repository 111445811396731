/**
 * Languages that can be used with Ava
 */
export enum Language {
  FR = 'fr',
  EN = 'en',
  ES = 'es',
}

/**
 * Default language used by Ava
 */
export const DefaultLanguage = Language.FR;

/**
 * List of all supported languages of Ava
 */
export const SupportedLanguages = [Language.FR, Language.EN, Language.ES];
