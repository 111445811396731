import { Role, UserDto } from '@ava/backend-shared';

export class AuthorizationHelper {
  public static isSuperAdmin(user: UserDto): boolean {
    return !!user.roles.find((r) => r === Role.SUPER_ADMIN);
  }

  public static isAdmin(user: UserDto): boolean {
    return !!user.roles.find((r) => r === Role.ADMINISTRATOR);
  }
}
