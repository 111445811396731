export interface MobileNotificationReplyDto {
  id: string;
  fromId: string;
  targetId: string;
  message: string;
  /**
   * The notification we are replying to
   */
  notificationId: string;
  responseCode: ResponseCode;
}

export enum ResponseCode {
  Ok = 'Ok',
  ComeNow = 'ComeNow',
  ComeIn15Min = 'ComeIn15Min',
  NotAvailable = 'NotAvailable',
}
