import { AxiosInstance } from 'axios';
import { DateTime } from 'luxon';
import { httpJsonClient } from '../http/httpClientFactory';
import { Logger } from '../utils/Logger';

export const logger = Logger.get('AppUpdateHelper.ts');

interface VersionFile {
  version: string;
}

export interface AppUpdateOptions {
  currentVersion: string;
  versionFileUrl: string;
  canReload?: () => Promise<boolean> | boolean;
  watchUpdateIntervalMinute?: number;
}

/**
 * This helper is used to update a frontend automatically
 */
export class AppUpdateHelper {
  private readonly httpClient: AxiosInstance;
  private watchUpdateInterval: any;
  private options: Required<AppUpdateOptions>;

  constructor(options: AppUpdateOptions) {
    this.httpClient = httpJsonClient(5_000);

    this.options = {
      watchUpdateIntervalMinute: 15,
      canReload: () => true,
      ...options,
    };
  }

  /**
   * Reload the front end when a new version is available
   */
  public watchUpdates(): void {
    this.unwatchUpdates();

    // launch immediately a check new version, before the first call inside the following watchUpdateInterval
    this.checkUpdates().catch((err) => logger.error(err));

    const intervalMs = this.options.watchUpdateIntervalMinute * 60 * 1000;
    this.watchUpdateInterval = setInterval(() => this.checkUpdates().catch((err) => logger.error(err)), intervalMs);
  }

  public unwatchUpdates(): void {
    if (this.watchUpdateInterval) {
      clearInterval(this.watchUpdateInterval);
    }
  }

  /**
   * This method check immediately if a new version is available.
   */
  public async checkUpdates() {
    if (!this.options.canReload || !(await this.options.canReload())) {
      logger.info('Update check skipped');
      return;
    }

    const latestVersion = await this.getLatestVersion();
    const newVersionDetected = latestVersion !== this.options.currentVersion;

    if (newVersionDetected) {
      this.reload();
    } else {
      logger.debug('No new version detected');
    }
  }

  /**
   * Get the latest version based on the version file
   */
  public async getLatestVersion(): Promise<string> {
    const versionFile = await this.httpClient
      .get<VersionFile>(this.options.versionFileUrl, {
        params: {
          preventCache: DateTime.utc().valueOf(), // timestamp of current utc time
        },
      })
      .then((res) => res.data);

    return versionFile.version;
  }

  /**
   * wrap which allows us to better test
   */
  public reload(): void {
    logger.warn('Page reloaded for application update');
    this.unwatchUpdates();
    window.location.reload();
  }
}
