import { Language } from './Language';

/**
 * Represents a text that can be translated in all supported bot languages
 */
export type I18nText = {
  [key in Language]: string;
};

export function isI18nText(t: unknown): t is I18nText {
  return !!(t && typeof t === 'object' && Language.EN in t && Language.FR in t && Language.ES in t);
}
