/**
 * When we use a new relay card, we should create a new driver and
 * declare the controller here.
 */
export enum ControllerModel {
  DS2824 = 'DS2824',
  KeRongBU = 'KeRongBU',
  IzyBat = 'IzyBat',
  FakeController = 'FakeController',
}
