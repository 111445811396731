import { I18nText } from '../common';
import { LocalizationInfoDto, LocalizationByLangDto } from '../localization';
import { UserFullDto } from '../users';
import { CandidateGroup } from './disambiguation/CandidateGroup';
import { LockerDto, LockerGroupDto } from '../locker-group';

/**
 * Commands are orders of execution sent from backend to clients.
 */
export enum BotCommandType {
  /**
   * This command is sent by the backend to request the client to use a new session ID.
   * Messages from the previous session should not be processed afterwards.
   *
   * Except some notifications.
   */
  NEW_SESSION = 'NEW_SESSION',
  /**
   * This command is used for ambiguities, for example to distinguish 'Monsieur Michel'
   * (Alexander) and 'Monsieur Michel' (Pierre)
   *
   * This command is sent by the backend with a list of candidate users.
   * The client must present the candidates to the visitor and return one of the users after
   * manual selection by the visitor.
   */
  CONFIRM_USER_IDENTITY = 'CONFIRM_USER_IDENTITY',
  /**
   * This command is used for ambiguities, for example to distinguish 'Service client'
   * (Brain New) and 'Service client' (Believe IT)
   *
   * This command is sent by the backend with a list of candidate groups.
   * The client must present the candidates to the visitor and return one of the groups after
   * manual selection by the visitor.
   */
  CONFIRM_GROUP_IDENTITY = 'CONFIRM_GROUP_IDENTITY',
  /**
   * This command is sent by the backend to request the name of the current visitor.
   */
  CONFIRM_VISITOR_IDENTITY = 'CONFIRM_VISITOR_IDENTITY',
  /**
   * This command is sent by the backend to request printing a visitor badge
   */
  PRINT_BADGE = 'PRINT_BADGE',
  /**
   * This command is sent by the backend to request scanning a visitor's ID card
   */
  TAKE_ID_CARD_PICTURE = 'TAKE_ID_CARD_PICTURE',
  /**
   * This command is sent by the backend when a previous request needs to be cancelled.
   * In this case the client must adapt his interface.
   *
   * For example if a recording is not significant, or if an interaction is empty (without significant transcription)
   */
  CANCEL_REQUEST = 'CANCEL_REQUEST',
  /**
   * This command is sent by the backend to request a photo of the visitor.
   */
  TAKE_PICTURE = 'TAKE_PICTURE',
  /**
   * This command is sent by the backend for the client to show a
   * selection and search box for users and groups.
   */
  SELECT_USER = 'SELECT_USER',
  /**
   * This command ends conversations to encourage users to leave the reception point.
   *
   * After receiving this command, the client will send an event indicating
   * that the conversation is actually over.
   *
   * Why this command/event back and forth ? To be sure not to end the conversation
   * if the reception point is active, for example if a visitor has taken the place of another visitor
   */
  TERMINATE_CONVERSATION = 'TERMINATE_CONVERSATION',

  SELECT_LOCALIZATION = 'SELECT_LOCALIZATION',
  LOCALIZATION_INDICATION = 'LOCALIZATION_INDICATION',
  CALL_NOTIFICATION_RECIPIENT = 'CALL_NOTIFICATION_RECIPIENT',
  CONFIRM_NOTIFICATION_RECIPIENT = 'CONFIRM_NOTIFICATION_RECIPIENT',
  TRANSMIT_NOTIFICATION = 'TRANSMIT_NOTIFICATION',

  /**
   * This command trigger the "Confirm assistance screen".
   *
   * This screen offers the visitor video assistance, with two possible choices: yes or no.
   */
  OFFERS_VIDEO_ASSISTANCE = 'OFFERS_VIDEO_ASSISTANCE',

  OPEN_LOCKER = 'OPEN_LOCKER',

  /**
   * This command trigger the locker code input screen
   */
  REQUEST_LOCKER_CODE = 'REQUEST_LOCKER_CODE',

  PRINT_RECEIPT_PROMPT = 'PRINT_RECEIPT_PROMPT',

  REQUEST_INVITATION_CODE = 'REQUEST_INVITATION_CODE',

  PROPOSE_COFFEE = 'PROPOSE_COFFEE',

  /**
   * This command is used to toggle the sentinel mode if the Rpoint has this option
   */
  TOGGLE_SENTINEL_MODE = 'TOGGLE_SENTINEL_MODE',
}

export interface NewSessionCommand {
  type: BotCommandType.NEW_SESSION;
}

export interface ConfirmUserIdentityCommand {
  type: BotCommandType.CONFIRM_USER_IDENTITY;
  users: UserFullDto[];
}

export interface ConfirmGroupIdentityCommand {
  type: BotCommandType.CONFIRM_GROUP_IDENTITY;
  groups: CandidateGroup[];
}

export interface VisitorIdentityConfirmationCommand {
  type: BotCommandType.CONFIRM_VISITOR_IDENTITY;
  message: I18nText;
}

export interface CancelRequestCommand {
  type: BotCommandType.CANCEL_REQUEST;
  rmsThreshold?: number;
}

export interface TakePictureCommand {
  type: BotCommandType.TAKE_PICTURE;
}

export interface SelectUserCommand {
  type: BotCommandType.SELECT_USER;
  message: I18nText;
  contextGroupPath?: string;
}

export interface TerminateConversation {
  type: BotCommandType.TERMINATE_CONVERSATION;
}

export interface SelectLocalizationCommand {
  type: BotCommandType.SELECT_LOCALIZATION;
  localizations: LocalizationInfoDto[];
}

export interface LocalizationIndication {
  type: BotCommandType.LOCALIZATION_INDICATION;
  localizations: LocalizationByLangDto;
}

export interface CallNotificationRecipientCommand {
  type: BotCommandType.CALL_NOTIFICATION_RECIPIENT;
  notificationId: string;
  visitor: string;
}

export interface ConfirmNotificationRecipientCommand {
  type: BotCommandType.CONFIRM_NOTIFICATION_RECIPIENT;
  notificationId: string;
  visitor: string;
}

export interface TransmitNotificationCommand {
  type: BotCommandType.TRANSMIT_NOTIFICATION;
  notificationId: string;
}

export interface PrintBadgeCommand {
  type: BotCommandType.PRINT_BADGE;
  visitorName: string;
  hostName: string;
}

export interface ProposeCoffeeCommand {
  type: BotCommandType.PROPOSE_COFFEE;
}

export interface TakeIdCardPictureCommand {
  type: BotCommandType.TAKE_ID_CARD_PICTURE;
}

export interface OffersVideoAssistance {
  type: BotCommandType.OFFERS_VIDEO_ASSISTANCE;
}
export interface OpenLockerCommand {
  type: BotCommandType.OPEN_LOCKER;
  lockerGroup: LockerGroupDto;
  locker: LockerDto;
  scenario: OpenLockerScenario;
}

export interface RequestLockerCodeCommand {
  type: BotCommandType.REQUEST_LOCKER_CODE;
}

export enum OpenLockerScenario {
  DELIVERY = 'Delivery',
  PICK_UP = 'Pick up',
}

export interface PrintReceiptPrompt {
  type: BotCommandType.PRINT_RECEIPT_PROMPT;
  deliveryId: string;
}

export interface RequestInvitationCode {
  type: BotCommandType.REQUEST_INVITATION_CODE;
}

export interface ToggleSentinelMode {
  type: BotCommandType.TOGGLE_SENTINEL_MODE;
  activate: boolean;
}

export declare type BotCommand =
  | NewSessionCommand
  | ConfirmUserIdentityCommand
  | ConfirmGroupIdentityCommand
  | CancelRequestCommand
  | TakePictureCommand
  | TakeIdCardPictureCommand
  | VisitorIdentityConfirmationCommand
  | SelectUserCommand
  | TerminateConversation
  | SelectLocalizationCommand
  | LocalizationIndication
  | CallNotificationRecipientCommand
  | ConfirmNotificationRecipientCommand
  | TransmitNotificationCommand
  | PrintBadgeCommand
  | OffersVideoAssistance
  | OpenLockerCommand
  | RequestLockerCodeCommand
  | PrintReceiptPrompt
  | RequestInvitationCode
  | ToggleSentinelMode
  | ProposeCoffeeCommand;
