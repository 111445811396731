import { NotificationChannel, OrganizationItemType, UserAbsenceDto } from '@ava/backend-shared';
import { routeReplace } from './routeReplace';

export class CallCenterRoutes {
  public static readonly home = () => '/callcenter/home/';
  public static readonly login = () => '/callcenter/login';
  public static readonly organization = () => '/callcenter/organization';
  public static readonly organizationUserDetails = (userId?: string) => routeReplace('/callcenter/organization/user/:userId', { userId });
  public static readonly organizationUserAbsenceDetails = (userId?: string) => routeReplace('/callcenter/organization/user/:userId/absence', { userId });
  public static readonly organizationGroupDetails = (groupId?: string) => routeReplace('/callcenter/organization/group/:groupId', { groupId });
  public static readonly organizationMessageSending = (entityId?: string, entityType?: OrganizationItemType, notificationMode?: NotificationChannel) =>
    routeReplace('/callcenter/organization/message/:entityId/:entityType/:notificationMode', { entityId, entityType, notificationMode });
  public static readonly factSheet = () => '/callcenter/fact-sheet';
  public static readonly lockerGroups = () => '/callcenter/locker-groups';
  public static readonly passwordReset = () => '/callcenter/reset-password';
}

export interface OrganizationUserDetailsParams {
  userId: string;
}

export interface OrganizationUserAbsenceParams {
  userId: string;
}

export interface OrganizationUserAbsenceState {
  absence?: UserAbsenceDto;
}

export interface OrganizationGroupDetailsParams {
  groupId: string;
}

export interface OrganizationMessageSendingParams {
  entityId: string;
  entityType: OrganizationItemType;
  notificationMode: NotificationChannel;
}
