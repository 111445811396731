import { wrap, WsMessage } from './WsMessage';

const WsDisconnectionPayloadType = 'WsDisconnectionPayload';

/**
 * This message must be sent when client disconnect
 */
export interface WsDisconnectionPayload {
  type: 'WsDisconnectionPayload';
  clientId: string;
  // All rooms names here will be leaved too
  leaveRooms: string[];
}

export function newWsDisconnectMessage(clientId: string, accessToken: string, leaveRooms: string[] = []): WsMessage<WsDisconnectionPayload> {
  return wrap(
    {
      type: WsDisconnectionPayloadType,
      clientId,
      leaveRooms,
    },
    { accessToken }
  );
}

export function isWsDisconnectionMessage(message: WsMessage<any> | undefined): message is WsMessage<WsDisconnectionPayload> {
  return (message?.body && 'type' in message.body && message.body.type === WsDisconnectionPayloadType) || false;
}

export function isWsDisconnectionPayload(payload: any | undefined): payload is WsDisconnectionPayload {
  return (payload && 'type' in payload && payload.type === WsDisconnectionPayloadType) || false;
}
